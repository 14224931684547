import React, { Component } from 'react';
import dtImage from '../../Images/dtFpImage.png'
const microsoftTeams = require('@microsoft/teams-js');


export class TabSettings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            user: ""
        };        
    }

    componentDidMount() {
        microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
            
            microsoftTeams.settings.setSettings({

               /// websiteUrl: "https://daniasoftware.com",
                contentUrl: window.location.origin + '/teams',
                entityId: "grayIconTab",
                suggestedDisplayName: "DynamicTemplate",
                removeUrl: ""
            });
            
            saveEvent.notifySuccess();
            
        });
        microsoftTeams.settings.setValidityState(true);
    }

    render() {       

        return (

            <div className="container align-content">                
                <img alt="Solution" className="responsive" src={dtImage} />                
                <h5>Click on save to append Dynamic Template to channel</h5>                
                </div>
        );
    }
}

export default TabSettings;
