import React, { Component } from "react";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { ValidateRequirements, GetRequirements, CreateDocumentAsBase64, CreateDocumentAsBytes, CreateDocumentAsBlob, CreateIntegrationDocument } from "./../ApiClients/LetterClient";
import ControlGroupWizard from "./../ControlGroupWizard";
import { GetSpinnerText, GetErrorMessages, GetCreationText } from "../Resources/Text";
import { GetCommonErrorMessage } from "../Resources/ErrorMessages";
import TeamsHelper from "../../utils/TeamsHelpers";
import WordHelper from "./../../utils/WordHelper";
import FileNameModal from "./FileNameModal";
import CustomInfoModal from "./CustomInfoModal";
import teamsHelper from "../../utils/TeamsHelpers";

const message = { Header: "", Message: "", Color: null, BackOnClose: false };

export class LetterRequirements extends Component {
	constructor(props) {
		super(props);
		this.state = {
			controlgroups: [],
			loading: true,
			spinnerText: GetSpinnerText().DefaultSpinnerText,
			showFilenameModal: false,
			documentBytes: null,
			fileExtension: "",
			contentType: "",
			errorMessage: "",
			customMsgModal: false,
			modalMessage: message,
			isIntegration: this.props.isIntegration,
		};
		this.setFileName = this.setFileName.bind(this);
	}

	componentDidMount() {
		this.populatLetterData();
	}

	//Return integration metadata if in "Integration mode"
	getIntegrationData = () => {
		if (this.state.isIntegration) {
			var data = sessionStorage.getItem("metadata");
			if (data) {
				console.log("isIntegration", this.props.isIntegration);
				console.log(data);
				return JSON.parse(data);
			}
		}
	};

	//Request data from backend
	populatLetterData = () => {
		GetRequirements(this.props.letterId, this.props.userId, this.getIntegrationData())
			.then((data) => {
				if (!data) {
					console.log("Cant fetch data ");
					message.Color = "#0091FF";
					message.Header = GetCommonErrorMessage().HeadLine;
					message.Message = GetCommonErrorMessage().Message;
					message.BackOnClose = true;
					this.setState({ loading: false, customMsgModal: true });
				} else if (data.length === 0) {
					//If no requirements just go create
					this.createDocument(data);
				} else {
					this.setState({ controlgroups: data, loading: false });
				}
			})
			.catch((error) => {
				console.log("Cant fetch data ", error);
				this.setState({ errorMessage: GetCommonErrorMessage().Message });
			});
	};

	//Server side group validation
	validateGroup = async (groups) => {
		this.setState({ validating: true, showValidationStatus: false });

		return ValidateRequirements(this.props.letterId, groups, this.getIntegrationData())
			.then((data) => {
				if (data.allValid) {
					this.setState({ hideControlWizard: false });
					this.createDocument(data.fieldGroups);
					return data;
				} else {
					return data;
				}
			})
			.catch((error) => {
				console.log("Cant fetch data ", error);
				this.setState({ validating: false });
			});
	};

	//Unselect current letter
	onClearSelection = () => {
		this.props.onClearSelection();
	};

	createDocument = async (fieldGroups) => {
		this.setState({ loading: true, spinnerText: GetSpinnerText().CreatingDocument });

		if (this.props.isTeamsInitialized) {
			if (teamsHelper.IsInChannel()) {
				//IF Teams app are loaded as tab in a Channel

				//Upload and open in Teams
				CreateDocumentAsBytes(this.props.letterId, 0, fieldGroups)
					.then((response) => {
						this.handleTeams(response.bytes, response.fileExtension, response.contentType);
					})
					.catch((error) => {
						this.setState({ loading: false, documentCreated: false });
						console.log(error);
					});
			} else {
				//IF Teams app are loaded as personal tab -
				//
				//Donloading file to local storage
				//

				//TODO: Show teams and select location
				//

				//Download
				CreateDocumentAsBlob(this.props.letterId, 0, fieldGroups)
					.then((result) => {
						let url = window.URL.createObjectURL(result.blob);
						let a = document.createElement("a");
						a.href = url;
						a.download = result.fileName;
						a.click();

						message.Header = GetCreationText().SuccessTitle;
						message.Message = GetCreationText().SuccessMessage;

						this.setState({ loading: false, documentCreated: true, customMsgModal: true, modalMessage: message });
					})
					.catch((error) => {
						this.setState({ loading: false, documentCreated: false, customMsgModal: true, errorMessage: GetCreationText().ErrorMessage });
						console.log(error);
					});
			}
		} else if (this.props.isOfficeInitialized) {
			//Create new document in office
			CreateDocumentAsBase64(this.props.letterId, 0, fieldGroups)
				.then((response) => {
					WordHelper.CreateNewDocument(response.fileContent);

					this.setState({ loading: false, documentCreated: true });
				})
				.catch((error) => {
					this.setState({ loading: false, documentCreated: false, customMsgModal: true, errorMessage: GetCreationText().ErrorMessage });
					console.log(error);
				});
		} else if (this.state.isIntegration) {
			//Create new integration document and redirect to url from response
			CreateIntegrationDocument(this.props.letterId, 0, fieldGroups, this.getIntegrationData())
				.then((response) => {
					window.location.href = response.redirectUrl;
				})
				.catch((error) => {
					message.Header = GetErrorMessages().UploadFailedHaader;
					message.Message = GetErrorMessages().UploadFailedSubText;
					message.Color = "red";
					this.setState({ loading: false, documentCreated: false, customMsgModal: true, modalMessage: message });
				});
		} else {
			//Download
			CreateDocumentAsBlob(this.props.letterId, 0, fieldGroups)
				.then((result) => {
					let url = window.URL.createObjectURL(result.blob);
					let a = document.createElement("a");
					a.href = url;
					a.download = result.fileName;
					a.click();

					this.setState({ loading: false, documentCreated: true });
					this.props.onClearSelection();
				})
				.catch((error) => {
					this.setState({ loading: false, documentCreated: false });
					console.log(error);
				});
		}
	};

	handleTeams = (bytes, fileextension, contenttype) => {
		this.setState({ documentBytes: bytes, fileExtension: fileextension, contentType: contenttype, showFilenameModal: true });
	};

	setFileName = (fileName) => {
		TeamsHelper.ValidateFileName(fileName, async (valid) => {
			if (valid) {
				var bytes = this.state.documentBytes;

				if (bytes !== null && fileName !== null) {
					await TeamsHelper.UploadAndOpen(this.state.documentBytes, fileName, this.state.contentType);
				} else {
					this.setState({ errorMessage: "Unable to create document" });
					console.log("Unable to create document");
				}
			} else {
				this.setState({ errorMessage: GetCreationText().FileExists });
				console.log("File already exists");
			}
		});
	};

	render() {
		return (
			<div>
				{this.state.showFilenameModal ? (
					<FileNameModal showModal={true} errorMessage={this.state.errorMessage} fileExtension={this.state.fileExtension} onSaveName={(filenameWithOutExtension) => this.setFileName(filenameWithOutExtension + this.state.fileExtension)} />
				) : null}
				{this.state.customMsgModal ? (
					<CustomInfoModal
						showModal={true}
						errorMessage={this.state.errorMessage}
						message={this.state.modalMessage.Message}
						header={this.state.modalMessage.Header}
						headerColor={this.state.modalMessage.Color}
						onClose={() => {
							if (this.state.modalMessage.BackOnClose) {
								this.onClearSelection();
							}
							this.setState({ customMsgModal: false });
						}}
					/>
				) : null}
				{this.state.loading ? (
					<Spinner label={this.state.spinnerText} size={SpinnerSize.large} SpinnerSize={SpinnerSize.large} ariaLive="assertive" labelPosition="right" />
				) : (
					<ControlGroupWizard controlgroups={this.state.controlgroups} onClearSelection={this.onClearSelection} onValidateGroup={(controlgroups) => this.validateGroup(controlgroups)} isTeamsInitialized={this.props.isTeamsInitialized} />
				)}
			</div>
		);
	}
}
export default LetterRequirements;
