export const GetClientSettings = () => {
	var request = new XMLHttpRequest();
	request.open("GET", "/configuration/clientsettings", false); // `false` makes the request synchronous
	request.send(null);

	if (request.status === 200) {
		return JSON.parse(request.responseText);
	} else {
		throw Error("Unable to read settings :" + request.status);
	}
};
