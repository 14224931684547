import React, { Component } from "react";
import { NavMenu } from "./Nav/NavMenu";
import { initializeIcons } from "@fluentui/react/lib/Icons";

export class Layout extends Component {
	static displayName = Layout.name;

	constructor(props) {
		super(props);
		initializeIcons();	
	}

	render() {
		return (
			<div>
				<div>
					{this.props.hideNavBar ? null : <NavMenu hideMenu={this.props.isOfficeInitialized || this.props.isTeamsInitialized} />}
					<div className="mainContainer">{this.props.children}</div>
				</div>
			</div>
		);
	}
}
