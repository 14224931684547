import React, { Component } from "react";
import LetterList from "../components/Letter/LetterList";
import LetterRequirements from "../components/Letter/LetterRequirements";
import AuthHelper from "./../components/Auth/AuthHelper";

export class Letters extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			letterid: "",
			letterSelected: false,
			value: "",
			userId: "",
		};
	}

	async componentDidMount() {
		//TODO: validering af at useraccount kan løses ved office login
		try {
			var username = await AuthHelper.getUserNameFromToken();
			var userId = username.substring(0, username.indexOf("@"));
			this.setState({ userId: userId });
		} catch (e) {
			console.log("Unable to read userId");
		}
	}

	selection = (x) => {
		if (x !== null && x.length > 0) {
			this.setState({ letterid: x, letterSelected: true });
		} else {
			this.setState({ letterid: x, letterSelected: false });
		}
	};

	clearSelection = () => {
		this.setState({ letterid: "", letterSelected: false });
	};

	render() {
		return (
			<div>
				{this.state.letterSelected ? (
					<LetterRequirements
						isIntegration={this.props.isIntegration}
						isOfficeInitialized={this.props.isOfficeInitialized}
						isTeamsInitialized={this.props.isTeamsInitialized}
						letterId={this.state.letterid}
						onClearSelection={this.clearSelection}
						userId={this.state.userId}
					/>
				) : (
					<LetterList onSelection={this.selection} />
				)}
			</div>
		);
	}
}
