import React, { Component } from "react";
import { getTheme } from '@fluentui/react';
import { GetConnect365Conflict } from "../components/Resources/Text";

const theme = getTheme();

export class Unsupported extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            letterid: "",
            letterSelected: false,
            value: "",
            userId: "",
        };
        console.info("isOfficeInitialized", this.props.isOfficeInitialized);
    }

    render() {
        return (
            <div>
                <div style={{ boxShadow: theme.effects.elevation8, paddingTop: "20px", margin: "30px" }}>
                    <div style={{ "padding": "10px" }}>
                        <h3>{GetConnect365Conflict().Title}</h3>
                        <h5>{GetConnect365Conflict().Subtext}</h5>
                    </div>
                </div>
            </div>
        );
    }
}