import { PostRequest, GetRequest, PostRequestBlob } from "./RequestHelper";

export const ValidateRequirements = async (letterId, controlgroups, integrationData) => {
    var obj = { FieldGroups: controlgroups, Integration: integrationData }
    return PostRequest("letter/ValidateRequirements/" + letterId, obj);
};

export const GetRequirements = async (letterId, userId, integrationData) => {
    if (integrationData)
        return PostRequest(`letter/GetRequirements/${letterId}/${userId}`, integrationData);
    else
        return PostRequest(`letter/GetRequirements/${letterId}/${userId}`, {});
};

export const CreateDocumentAsBlob = async (letterId, fileFormat, fieldGroups) => {
    let request = { fileFormat: fileFormat, fieldGroups: fieldGroups };
    return PostRequestBlob("letter/CreateDocument/" + letterId, request);
};

export const CreateDocumentAsBase64 = async (letterId, fileFormat, fieldGroups) => {
    let request = { fileFormat: fileFormat, fieldGroups: fieldGroups };
    return PostRequest("letter/CreateDocumentAsBase64/" + letterId, request);
};

export const CreateDocumentAsBytes = async (letterId, fileFormat, fieldGroups) => {
    let request = { fileFormat: fileFormat, fieldGroups: fieldGroups };
    return PostRequest("letter/CreateDocumentAsBytes/" + letterId, request);
};
export const CreateIntegrationDocument = async (letterId, fileFormat, fieldGroups, integrationData) => {
    let createDocumentRequest = {
        fileFormat: fileFormat,
        fieldGroups: fieldGroups
    };

    let createIntegrationDocumentRequest = {
        CreateDocumentRequest: createDocumentRequest,
        Integration: integrationData
    }
    return PostRequest("letter/CreateIntegrationDocument/" + letterId, createIntegrationDocumentRequest);
};

export const GetLetters = async (type, path) => {
    let data = { cloudTemplateType: type, path: path }    
    return PostRequest("letter/letters/", data);
};