import React, { Component } from "react";
import { Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import "./NavMenu.css";
import logo from "../../Images/dtcubes.png";
import { GetMenuText } from "../Resources/Text";
import { LanguageSelector } from "../Language/LanguageSelector";

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            isOpen: false,
        };
        this.toggle = this.toggle.bind(this);
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    toggle() {
        this.setState({ isOpen: !this.state.isOpen });
    }



    render() {
        return (
            <header style={{ marginBottom: "80px" }}>
                <Navbar className="navbar-expand-sm navbar-fixed-top navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" fixed="top" light>
                    <NavbarBrand href="/">
                        <img src={logo} alt="Logo" style={{ maxWidth: "30px" }} />
                    </NavbarBrand>
                    {this.props.hideMenu ? null : (
                        <span>
                            <NavbarToggler onClick={this.toggle} />
                            <Collapse isOpen={this.state.isOpen} navbar>
                                <Nav className="mr-auto" navbar>
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret>
                                            Menu
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            <DropdownItem>
                                                <NavLink href="/standardcontent">{GetMenuText().PhraseText}</NavLink>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <NavLink href="/Letter">{GetMenuText().TemplateText}</NavLink>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <NavLink href="/profile">{GetMenuText().ProfileText}</NavLink>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </Nav>
                            </Collapse>
                        </span>
                    )}
                    <div className="languageSelector ">
                        <LanguageSelector />
                    </div>
                </Navbar>
            </header>
        );
    }
}
