import React, { Component } from 'react';
import '../../components/Nav/NavMenu.css';

export class Landing extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            user: ""
        };
    }    


    render() {
        return (

            <div>Landing</div>
        );
    }
}

export default Landing;
