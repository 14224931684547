import React, { Component } from 'react';
import '../../components/Nav/NavMenu.css';
import { GetClientSettings } from '../../components/ApiClients/SettingsClient'
const microsoftTeams = require('@microsoft/teams-js');

const config = GetClientSettings()

export class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            user: "",
            url: ""
        };
    }

    componentDidMount() {        

        microsoftTeams.initialize();

        microsoftTeams.getContext( (context) =>{
            // Generate random state string and store it, so we can verify it in the callback
            let state = "4f87eabb-88f4-4378-9dc8-9d5e0c423547" // _guid() is a helper function in the sample
            localStorage.setItem("simple.state", state);
            localStorage.removeItem("simple.error");
            // Go to the Azure AD authorization endpoint
            let queryParams = {
                client_id: config.dtMsTeamsAppId,
                response_type: "id_token token",
                response_mode: "fragment",
                scope: "User.Read openid Group.ReadWrite.All",
                redirect_uri: window.location.origin + "/teams/landing",
                nonce: "4f87eabb-88f4-4378-9dc8-9d5e0c423547",
                state: state,
                // The context object is populated by Teams; the loginHint attribute
                // is used as hinting information
                login_hint: context.loginHint,
            };
            var q = Object.keys(queryParams).map(key => key + '=' + queryParams[key]).join('&');
            

            let authorizeEndpoint = "https://login.microsoftonline.com/" + context.tid + "/oauth2/v2.0/authorize?" + q;

            localStorage.setItem("url", authorizeEndpoint);
            this.setState({url:authorizeEndpoint})
            return window.location.assign(authorizeEndpoint);
        });

    }



    parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    };

    
    render() {       

        return (
            <div>
               
                </div>
        );
    }
}

export default Login;
