import * as React from 'react';
import ReactDOM from 'react-dom'
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
let resolve;
export class CustomInfoDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: false,
            title: "",
            sectionTwo: "",
            sectionOne: ""
        };
    }
    getprops() {
        return {
            type: DialogType.normal,
            title: this.state.title,
            closeButtonAriaLabel: 'Close',
            subText: <div><p> {this.state.sectionOne}</p> <p>{this.state.sectionTwo}</p></div>,
        };
    }

    onClick = result => {
        this.props.result(result)

        this.setState({ hidden: true })
    }

    handleCancel() {
        this.setState({ isOpen: false });
        resolve(false);
    }

    handleConfirm() {
        this.setState({ isOpen: false });
        resolve(true);
    }

    show = (title, sectionOne, sectionTwo) => {
        this.setState({ isOpen: true, title: title, sectionOne: sectionOne, sectionTwo: sectionTwo });
        return new Promise((res) => {
            resolve = res;
        });
    }
    static create() {
        const containerElement = document.createElement('div');
        document.body.appendChild(containerElement);
        return ReactDOM.render(<CustomInfoDialog />, containerElement);
    }
    render() {
        return (<div>
            {this.state.isOpen ?
                <Dialog
                    hidden={this.state.hidden}

                    dialogContentProps={this.getprops()}
                >
                    <DialogFooter>
                        <PrimaryButton onClick={() => this.handleConfirm()} text="OK" />
                    </DialogFooter>
                </Dialog> : null}

        </div>);
    }
}

export default CustomInfoDialog;