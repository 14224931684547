import React, { Component } from "react";
import authHelper from "./AuthHelper";
import { Redirect } from "react-router";
import "../Nav/NavMenu.css";
import { Button } from "reactstrap";

export class Auth extends Component {
	constructor(props, context) {
		super(props, context);
		console.log("to", this.props);
		const { from } = this.props[0].location.state || { from: { pathname: "/" } };

		//var user = authHelper.userAccount();

		this.state = {
			isAuthenticated: false, //(user !== null),
			//user: user,
			error: null,
			token: "",
			redirect: from,
		};
	}

	login = async () => {
		authHelper
			.login()
			.then((x) => {
				var user = authHelper.userAccount();
				console.log(user);
				this.setState({ isAuthenticated: true, user: user });
			})
			.catch((x) => console.log(x));
	};

	sleep = async (ms) => {
		return new Promise((resolve) => setTimeout(resolve, ms));
	};

	async componentDidMount() {
		if (!this.state.isAuthenticated) {
			var result = await authHelper.isAuthenticated(this.props.officeLoaded);
			//this.setState({ isAuthenticated: result });

			await this.login();
			//Wait for token
			if (this.props.onAuthenticated != null) {
				this.props.onAuthenticated(true);
			}
		}
	}

	render() {
		return this.state.isAuthenticated ? (
			<Redirect to={this.state.redirect} />
		) : (
			<div>
				<Button color="primary" onClick={this.login.bind(this)} value="TEST">
					Login
				</Button>
			</div>
		);
	}
}

export default Auth;
