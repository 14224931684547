import React, { useState, useEffect } from "react";
import { TextField, DetailsList, DetailsListLayoutMode, SelectionMode, mergeStyleSets, initializeIcons, ShimmeredDetailsList, selection } from "@fluentui/react";
import { Row, Col } from "reactstrap";
import { GetFileExploreDataListText } from "../Resources/Text";
import Breadcrumbs from "../Breadcrumbs";

// Initialize icons in case this example uses them
initializeIcons();

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: "16px",
    },
    fileIconCell: {
        textAlign: "center",
        selectors: {
            "&:before": {
                content: ".",
                display: "inline-block",
                verticalAlign: "middle",
                height: "100%",
                width: "0px",
                visibility: "hidden",
            },
        },
    },
    fileIconImg: {
        verticalAlign: "middle",
        maxHeight: "16px",
        maxWidth: "16px",
    },
    controlWrapper: {
        display: "flex",
        flexWrap: "wrap",

        marginRight: "0px",
        justifyContent: "flex-end",
    },

    selectionDetails: {
        marginBottom: "20px",
    },
});

const controlStyles = {
    root: {
        display: "flex",
        margin: "0 0 0 0",
        maxWidth: "300px",
    },
};

const datalist = {
    detailsHeader: {
        marginTop: "0px",
    },
};



export default function CustomDataList(props) {
    const [items, setItems] = useState([]);
    const [path, setPath] = useState();
    let active = false;

    //Load language Ressource
    const resourceText = GetFileExploreDataListText();

    useEffect(() => {
        if (!props.loading) {
        active = true;
        console.log(props.data);
        setItems(props.data);
        setPath(props.path);
        }
        
    });

    const onItemInvoked = (item) => {
        if (item && active) {
            active = false;
            props.onSelection(item);
        }
    };

    const getKey = (item, index) => {
        if (item) {
            return item.key;
        }
    };

    const onColumnClick = (ev, column) => {

        let allColumns = initColumns();
        const currColumn = allColumns.filter((currCol) => column.key === currCol.key)[0];
        allColumns.forEach((newCol) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });

        const newItems = copyAndSort([...items], currColumn.fieldName, currColumn.isSortedDescending);
        setItems(newItems);
        setColumns(allColumns);
    };

    const initColumns = () => {
        return [
            {
                key: "column1",
                name: GetFileExploreDataListText().FileType,
                className: classNames.fileIconCell,
                iconClassName: classNames.fileIconHeaderIcon,
                ariaLabel: "Column operations for File type, Press to sort on File type",
                iconName: "Page",
                isIconOnly: true,
                fieldName: "name",
                minWidth: 16,
                maxWidth: 16,
                //onColumnClick: onColumnClick,
                onRender: (item) => {
                    return <img src={item.iconName} className={classNames.fileIconImg} alt={item.fileType + " icon"} />;
                },
            },
            {
                key: "column2",
                name: GetFileExploreDataListText().Name,
                fieldName: "name",
                minWidth: 210,
                maxWidth: 350,
                isRowHeader: true,
                isResizable: true,
                //isSorted: true,
                //isSortedDescending: false,
                //sortAscendingAriaLabel: "Sorted A to Z",
                //sortDescendingAriaLabel: "Sorted Z to A",
                //onColumnClick: onColumnClick,
                data: "string",
                isPadded: true,
            },
            {
                key: "column3",
                name: GetFileExploreDataListText().Description,
                fieldName: "descriptionValue",
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                //onColumnClick: onColumnClick,
                data: "number",
                onRender: (item) => {
                    return <span>{item.description}</span>;
                },
                isPadded: true,
            },
        ];
    };

    const [columns, setColumns] = useState(initColumns());

    return (
        <div>
            <Row>
                <Col>
                    <div>{path ? <Breadcrumbs onClick={(path) => props.onPathUp(path)} path={path} /> : null}</div>
                </Col>
            </Row>

            <div>
                <ShimmeredDetailsList
                    onDidUpdate={(x) => {
                        //Remove focus from datalist
                        x.state.focusedItemIndex = -1;
                        if (document.activeElement.tagName !== "INPUT") {
                            document.activeElement.blur();
                        }
                    }}
                    styles={datalist}
                    items={items}
                    //columns={initColumns()}
                    columns={columns}
                    enableShimmer={props.loading}
                    selectionMode={SelectionMode.none}
                    getKey={getKey}
                    setKey={Date.now.toString()}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    onItemInvoked={onItemInvoked}
                    onActiveItemChanged={onItemInvoked}
                />
            </div>
        </div>
    );
}

function copyAndSort(items, columnKey, isSortedDescending) {
    const key = columnKey;
    return items.slice(0).sort((a, b) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}