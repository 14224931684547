import React, { useState } from "react";
import { ActionButton, getTheme, mergeStyleSets, FontWeights, Modal, initializeIcons, TextField, MessageBar, MessageBarType } from "@fluentui/react";
import { GetCreationText } from "../Resources/Text";

const FileNameModal = (props) => {
	initializeIcons();
	const { showModal, onSaveName, errorMessage, fileExtension } = props;

	const [modal, setModal] = useState(showModal);
	const [filenameWithoutextension, setFilename] = useState("");
	const theme = getTheme();

	const contentStyles = mergeStyleSets({
		container: {
			display: "flex",
			flexFlow: "column nowrap",
			alignItems: "stretch",
		},
		header: [
			// tslint:disable-next-line:deprecation
			theme.fonts.xLargePlus,
			{
				flex: "1 1 auto",
				borderTop: `4px solid ${theme.palette.themePrimary}`,
				color: theme.palette.neutralPrimary,
				display: "flex",
				alignItems: "center",
				fontWeight: FontWeights.semibold,
				padding: "12px 12px 14px 24px",
			},
		],
		body: {
			flex: "4 4 auto",
			padding: "0 24px 24px 24px",
			overflowY: "hidden",
			selectors: {
				p: { margin: "14px 0" },
				"p:first-child": { marginTop: 0 },
				"p:last-child": { marginBottom: 0 },
			},
		},
	});

	const saveName = () => {
		if (filenameWithoutextension.length > 0) {
			onSaveName(filenameWithoutextension);
			setModal(!modal);
		}
	};

	const onKeyDown = (event) => {
		// 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
		if (event.key === "Enter") {
			event.preventDefault();
			event.stopPropagation();
			saveName();
		}
	};
	const addFriendIcon = { iconName: "Save" };

	return (
		<div>
			<Modal isOpen={showModal} isBlocking={true} dragOptions={false}>
				<div className={contentStyles.header}>
					<span id={"test"}>Filnavn</span>
				</div>
				<div className={contentStyles.body}>
					<TextField onChange={(e) => setFilename(e.target.value)} label={GetCreationText().Save} suffix={fileExtension} ariaLabel="filename" onKeyDown={onKeyDown} />

					<ActionButton onClick={() => saveName()} className="mt-1 mb-1 float-right" iconProps={addFriendIcon} allowDisabledFocus>
						{GetCreationText().Save}
					</ActionButton>

					{errorMessage.length > 0 ? (
						<div className="pt-1">
							<MessageBar messageBarType={MessageBarType.error} isMultiline={false} onDismiss={() => {}} dismissButtonAriaLabel="Close">
								{errorMessage}
							</MessageBar>
						</div>
					) : null}
				</div>
			</Modal>
		</div>
	);
};

export default FileNameModal;
