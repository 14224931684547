import { UploadDocumentToGroup, FileExists } from "../components/ApiClients/GraphClient";

const microsoftTeams = require("@microsoft/teams-js");

//TODO: Context bliver ulæst i Index - Bør man cache det requet for optimering
export class TeamsHelper {
	UploadAndOpen = async (bytes, fileName, contentType) => {
		microsoftTeams.getContext(async (context) => {
			if (context) {
				console.log(context);

				var result = await UploadDocumentToGroup(fileName, contentType, context.groupId, context.channelRelativeUrl, base64ToArrayBuffer(bytes));
				if (result == null || result.eTag == null) {
					console.log("Unable to upload document: " + fileName);
					throw Error("Unable to upload document");
				} else {
					this.OpenDocumentInTeams(result.eTag, fileName);
				}
			}
		});
	};

	IsInChannel = () => {
		var isInChannel = sessionStorage.getItem("isInChannel");
		return isInChannel === "true";
	};

	ValidateFileName = async (fileName, callback) => {
		microsoftTeams.getContext(async (context) => {
			if (context) {
				console.log(context);

				var result = await FileExists(fileName, context.groupId, context.channelRelativeUrl);
				if (result == null) {
					console.log("Unable validate filename: " + fileName);
					throw Error("Unable validate filename");
				} else {
					callback(!result);
				}
			}
		});
	};

	OpenDocumentInTeams(etag, filename) {
		var start = etag.indexOf("{");
		var slut = etag.indexOf("}") + 1;
		etag = etag.substring(start + 1, slut - start);

		microsoftTeams.getContext(function (context) {
			if (context) {
				var deeplink = createDeepLink(etag, context.tid, context.teamSiteDomain, context.channelRelativeUrl, filename, context.teamSiteUrl, context.channelId, context.groupId);
				console.log(deeplink);
				localStorage.setItem("deeplink", deeplink);
				microsoftTeams.executeDeepLink(deeplink);
			}
		});
	}
}

const createDeepLink = (etag, tenantId, teamSiteDomain, channelRelativeUrl, fileName, teamSiteUrl, channelId, groupId) => {
	var queryParams = {
		tanatId: tenantId,
		objectUrl: "https://" + teamSiteDomain + channelRelativeUrl + "/" + fileName,
		baseUrl: teamSiteUrl,
		serviceName: "teams",
		threadId: channelId,
		groupId: groupId,
	};

	var q = Object.keys(queryParams)
		.map((key) => key + "=" + queryParams[key])
		.join("&");

	return "https://teams.microsoft.com/l/file/" + etag + "?" + encodeURI(q);
};

const base64ToArrayBuffer = (base64) => {
	var binary_string = window.atob(base64);
	var len = binary_string.length;
	var bytes = new Uint8Array(len);
	for (var i = 0; i < len; i++) {
		bytes[i] = binary_string.charCodeAt(i);
	}
	return bytes.buffer;
};

const teamsHelper = new TeamsHelper();

export default teamsHelper;
