import React, { Component } from "react";
import "../components/Nav/NavMenu.css";
import authHelper from "../components/Auth/AuthHelper";
import { Label } from "@fluentui/react";

export class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            user: "",
        };
    }

    componentDidMount() {
        authHelper
            .getToken()
            .then((token) => {
                if (token != null) {
                    this.setState({ loading: false, user: this.parseJwt(token) });
                }
            })
            .catch("Cant load profile");
    }

    parseJwt(token) {
        var base64Url = token.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
            atob(base64)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
        );

        return JSON.parse(jsonPayload);
    }

    userAcount = () => {
        return this.parseJwt(this.props.token);
    };

    render() {
        var userInfo = this.state.user;

        return this.state.loading ? (
            <div>Fetcing profile</div>
        ) : (
                <div>

                    <Label> Unique Name: {userInfo.unique_name}</Label>
                    <Label>Name: {userInfo.name}</Label>
                    <Label>Issuer: {userInfo.iss}</Label>
                    <Label>Tid: {userInfo.tid}</Label>
                </div>
            );
    }
}

export default Profile;