import getLanguage from "../Language/LanguageHelper";

const defaultLanguageCode = "en-GB";
const ConfirmDialog = {
	"en-GB": {
		Yes: "Yes",
		No: "No",
	},
	"da-DK": {
		Yes: "Ja",
		No: "Nej",
	},
	"se-SE": {
		Yes: "Ja",
		No: "Nej",
	},
	"nb-NO": {
		Yes: "Ja",
		No: "Nei",
	},
};

const EnclosureInsertDialog = {
	"en-GB": {
		Title: "Confirm insert of appendix",
		Subtext: "The appendix will be formatted to the current document layout. Do you want to insert the appendix?",
	},
	"da-DK": {
		Title: "Bekræft indsættelse af bilag",
		Subtext: "Bilag formateres efter det aktuelle dokumentlayout. Vil du indsætte bilaget?",
	},
	"se-SE": {
		Title: "Bekräfta infogning av bilagor",
		Subtext: "Bilagor formateras efter aktuell dokumentlayouten. Vill du infoga bilagan?",
	},
	"nb-NO": {
		Title: "Bekreft innsetting av vedlegg",
		Subtext: "Vedlegg vil følge det aktuelle dokumentets layout. Skal vedlegg settes inn?",
	},
};

const Connect365Conflict = {
	"en-GB": {
		Title: "Conflict - finish document editing first",
		Subtext: "To use a new template, you must save and close the open document first.",
	},
	"da-DK": {
		Title: "Konflikt – afslut dokumentredigering først",
		Subtext: "For at bruge en ny skabelon skal du først gemme og afslutte det åbne dokument.",
	},
	"se-SE": {
		Title: "Konflikt - avsluta dokumentredigeringen först",
		Subtext: "Använda en ny mall: spara först och avsluta det öppna dokumentet.",
	},
	"nb-NO": {
		Title: "Konflikt - fullfør dokumentredigering først",
		Subtext: "For å bruke en ny mal må du først lagre og avslutte det åpne dokumentet.",
	},
};

const ErrorMessages = {
	"en-GB": {
		UploadFailedHaader: "Document upload failed",
		UploadFailedSubText: "Try again or contact your administrator",
	},
	"da-DK": {
		UploadFailedHaader: "Upload af dokument mislykkedes",
		UploadFailedSubText: "Prøv igen, eller kontakt din administrator",
	},
	"se-SE": {
		UploadFailedHaader: "Det gick inte att ladda upp dokumentet",
		UploadFailedSubText: "Försök igen eller kontakta din administratör",
	},
	"nb-NO": {
		UploadFailedHaader: "Opplasting av dokument mislyktes",
		UploadFailedSubText: "Prøv på nytt eller kontakt administratoren din",
	},
};

const AttachmentsInsertDialog = {
	"en-GB": {
		Title: "Attachments",
		Subtext: "Attachments are not supported in this version. Therefore, the following attachments are not inserted:",
	},
	"da-DK": {
		Title: "Forsendelsesbilag",
		Subtext: "Forsendelsesbilag understøttes ikke i denne version. Følgende forsendelsesbilag indsættes derfor ikke:",
	},
	"se-SE": {
		Title: "Separata bilagor",
		Subtext: "Separata bilagor stöds inte i den här versionen. Följande bilagor infogas därför inte:",
	},
	"nb-NO": {
		Title: "Forsendelsesvedlegg",
		Subtext: "Forsendelsesvedlegg støttes ikke i denne versjonen. Følgende forsendelsesvedlegg settes derfor ikke inn:",
	},
};

const FileExploreDataList = {
	"en-GB": {
		FilterPlaceholder: "Filter by name",
		FileType: "File type",
		Name: "Name",
		Description: "Description",
		LevelUp: "Level up",
	},
	"da-DK": {
		FilterPlaceholder: "Filtrer efter navn",
		FileType: "Filtype",
		Name: "Navn",
		Description: "Beskrivelse",
		LevelUp: "Niveau op",
	},
	"se-SE": {
		FilterPlaceholder: "Filtrera på namn",
		FileType: "Filtyp",
		Name: "Namn",
		Description: "Beskrivning",
		LevelUp: "Nivå upp",
	},

	"nb-NO": {
		FilterPlaceholder: "Filtrere etter navn",
		FileType: "Filtype",
		Name: "Navn",
		Description: "Beskrivelse",
		LevelUp: "Nivå opp",
	},
};

const ControlGroupWizard = {
	"en-GB": {
		ButtonBack: "Back",
		ButtonNext: "Next",
	},
	"da-DK": {
		ButtonBack: "Tilbage",
		ButtonNext: "Næste",
	},
	"se-SE": {
		ButtonBack: "Tillbaka",
		ButtonNext: "Nästa",
	},

	"nb-NO": {
		ButtonBack: "Tilbake",
		ButtonNext: "Neste",
	},
};

const Spinner = {
	"en-GB": {
		DefaultSpinnerText: "Loading....",
		CreatingDocument: "Creating document.....",
		SignIn: "Signing in....",
		Inserting: "Inserting....",
	},
	"da-DK": {
		DefaultSpinnerText: "Indlæser....",
		CreatingDocument: "Danner dokument....",
		SignIn: "Logger ind....",
		Inserting: "Inserting....",
	},
	"se-SE": {
		DefaultSpinnerText: "Läser in....",
		CreatingDocument: "Skapar dokument....",
		SignIn: "Loggar in....",
		Inserting: "Infoga....",
	},

	"nb-NO": {
		DefaultSpinnerText: "Laster inn....",
		CreatingDocument: "Oppretter dokument....",
		SignIn: "Logger på....",
		Inserting: "Sette inn....",
	},
};

const ProfilePicker = {
	"en-GB": {
		SuggestionsHeaderText: "Suggested profile",
		MostRecentlyUsedHeaderText: "Suggested contacts",
		NoResultsFoundText: "No results found",
		LoadingText: "Loading",
		SuggestionsAvailableAlertText: "Profile Picker suggestions available",
		SuggestionsContainerAriaLabel: "Suggested contacts",
		PlaceHolder: "Search profile",
		SearchLabel: "Search",
	},
	"da-DK": {
		SuggestionsHeaderText: "Foreslået profil",
		MostRecentlyUsedHeaderText: "Foreslået kontakt",
		NoResultsFoundText: "Intet resultat",
		LoadingText: "Indlæser",
		SuggestionsAvailableAlertText: "Profilvælgerforslag tilgængeligt",
		SuggestionsContainerAriaLabel: "Foreslået kontakt",
		PlaceHolder: "Søg efter profil",
		SearchLabel: "Søg profil",
	},
	"se-SE": {
		SuggestionsHeaderText: "Föreslagen profil",
		MostRecentlyUsedHeaderText: "Föreslagen kontakt",
		NoResultsFoundText: "Inget resultat",
		LoadingText: "Läser in",
		SuggestionsAvailableAlertText: "Profilväljarförslag finns tillgängligt",
		SuggestionsContainerAriaLabel: "Föreslagen kontakt",
		PlaceHolder: "Sök efter profil",
		SearchLabel: "Sök profil",
	},

	"nb-NO": {
		SuggestionsHeaderText: "Foreslått profil",
		MostRecentlyUsedHeaderText: "Foreslått kontakt",
		NoResultsFoundText: "Ingen resultater",
		LoadingText: "Laster inn",
		SuggestionsAvailableAlertText: "Profilvelgerforslag tilgjengelig",
		SuggestionsContainerAriaLabel: "Foreslått kontakt",
		PlaceHolder: "Søk etter profil",
		SearchLabel: "Søk profil",
	},
};

const DatePickerStrings = {
	"en-GB": {
		months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],

		shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],

		days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],

		shortDays: ["S", "M", "T", "W", "T", "F", "S"],

		goToToday: "Go to today",
		prevMonthAriaLabel: "Go to previous month",
		nextMonthAriaLabel: "Go to next month",
		prevYearAriaLabel: "Go to previous year",
		nextYearAriaLabel: "Go to next year",
		closeButtonAriaLabel: "Close date picker",
	},
	"da-DK": {
		months: ["Januar", "Februar", "Marts", "April", "Maj", "Juni", "Juli", "August", "September", "Oktober", "November", "December"],

		shortMonths: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"],

		days: ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"],

		shortDays: ["S", "M", "T", "O", "T", "F", "L"],

		goToToday: "Gå til idag",
		prevMonthAriaLabel: "Gå til forrige måned",
		nextMonthAriaLabel: "Gå til næste måned",
		prevYearAriaLabel: "Gå til forrige år",
		nextYearAriaLabel: "Gå til næste år",
		closeButtonAriaLabel: "Luk datovælger",
	},
	"se-SE": {
		months: ["Januari", "Februari", "Mars", "April", "Maj", "Juni", "Juli", "Augusti", "September", "Oktober", "November", "December"],

		shortMonths: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"],

		days: ["Söndag", "Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag", "L​lördag"],

		shortDays: ["S", "Man", "T", "o", "T", "F", "L"],

		goToToday: "Gå till idag",
		prevMonthAriaLabel: "Gå till föregående månad",
		nextMonthAriaLabel: "Gå till nästa månad",
		prevYearAriaLabel: "Gå till föregående år",
		nextYearAriaLabel: "Gå till nästa år",
		closeButtonAriaLabel: "Stäng datumväljaren",
	},
	"nb-NO": {
		months: ["Januar", "Februar", "Mars", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Desember"],

		shortMonths: ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Des"],

		days: ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"],

		shortDays: ["S", "Man", "T", "o", "T", "F", "L"],

		goToToday: "Gå til i dag",
		prevMonthAriaLabel: "Gå til forrige måned",
		nextMonthAriaLabel: "Gå til neste måned",
		prevYearAriaLabel: "Gå til året før",
		nextYearAriaLabel: "Gå til neste år",
		closeButtonAriaLabel: "Lukk datovelgeren",
	},
};

const FolderText = {
	"en-GB": {
		Description: "Folder",
	},
	"da-DK": {
		Description: "Mappe",
	},
	"se-SE": {
		Description: "Mapp",
	},
	"nb-NO": {
		Description: "Mappe",
	},
};

const CreationText = {
	"en-GB": {
		ErrorMessage: "Document was not created",
		FileExists: "File already exists",
		FileNameLabel: "Enter File name",
		Save: "Save",
		SuccessMessage: "Document was created and saved under  'Files' -> 'Downloads'",
		SuccessTitle: "Document was created",
	},
	"da-DK": {
		ErrorMessage: "Dokument blev ikke oprettet",
		FileExists: "Fil findes allerede",
		FileNameLabel: "Angiv filnavn",
		Save: "Gem",
		SuccessMessage: "Dokument skapades och sparades under 'Filer' -> 'Hämtningar'",
		SuccessTitle: "Dokument blev oprettet",
	},
	"se-SE": {
		ErrorMessage: "Dokument skapades inte",
		FileExists: "Filen finns redan",
		FileNameLabel: "Ange filnamn",
		Save: "Spara",
		SuccessMessage: "Dokument er oprettet og gemt under 'Filer' -> 'Downloads'",
		SuccessTitle: "Dokument skapades",
	},
	"nb-NO": {
		ErrorMessage: "Dokumentet ble ikke opprettet",
		FileExists: "Filen finnes allerede",
		FileNameLabel: "Angi Filnavn",
		Save: "Lagre",
		SuccessMessage: "Dokumentet ble opprettet og lagret under 'Filer' -> 'Nedlastinger'",
		SuccessTitle: "Dokumentet ble opprettet",
	},
};

const MenuText = {
	"en-GB": {
		PhraseText: "Standard Content",
		TemplateText: "Cloud templates",
		ProfileText: "Profile",
	},
	"da-DK": {
		PhraseText: "Fraser",
		TemplateText: "Cloud skabeloner",
		ProfileText: "Profil",
	},
	"se-SE": {
		PhraseText: "Fraser",
		TemplateText: "Cloud mallar",
		ProfileText: "Profil",
	},
	"nb-NO": {
		PhraseText: "Fraser",
		TemplateText: "Cloud maler",
		ProfileText: "Profil",
	},
};

export const GetMenuText = () => {
	var result = MenuText[getLanguage().code];

	if (result == null) {
		return MenuText[defaultLanguageCode];
	} else {
		return result;
	}
};

export const GetEnclosureInsertDialogText = () => {
	var result = EnclosureInsertDialog[getLanguage().code];

	if (result == null) {
		return EnclosureInsertDialog[defaultLanguageCode];
	} else {
		return result;
	}
};

export const GetAttachmentsInsertDialog = () => {
	var result = AttachmentsInsertDialog[getLanguage().code];

	if (result == null) {
		return AttachmentsInsertDialog[defaultLanguageCode];
	} else {
		return result;
	}
};

export const GetConfirmDialogText = () => {
	var result = ConfirmDialog[getLanguage().code];

	if (result == null) {
		return ConfirmDialog[defaultLanguageCode];
	} else {
		return result;
	}
};

export const GetDataPickerText = () => {
	var result = DatePickerStrings[getLanguage().code];

	if (result == null) {
		return DatePickerStrings[defaultLanguageCode];
	} else {
		return result;
	}
};

export const GetSpinnerText = () => {
	var result = Spinner[getLanguage().code];

	if (result == null) {
		return Spinner[defaultLanguageCode];
	} else {
		return result;
	}
};

export const GetFileExploreDataListText = () => {
	var result = FileExploreDataList[getLanguage().code];

	if (result == null) {
		return FileExploreDataList[defaultLanguageCode];
	} else {
		return result;
	}
};

export const GetControlGroupWizardText = () => {
	var result = ControlGroupWizard[getLanguage().code];

	if (result == null) {
		return ControlGroupWizard[defaultLanguageCode];
	} else {
		return result;
	}
};

export const GetProfilePickerText = () => {
	var result = ProfilePicker[getLanguage().code];

	if (result == null) {
		return ProfilePicker[defaultLanguageCode];
	} else {
		return result;
	}
};

export const GetErrorMessages = () => {
	var result = ErrorMessages[getLanguage().code];

	if (result == null) {
		return ErrorMessages[defaultLanguageCode];
	} else {
		return result;
	}
};

export const GetConnect365Conflict = () => {
	var result = Connect365Conflict[getLanguage().code];

	if (result == null) {
		return Connect365Conflict[defaultLanguageCode];
	} else {
		return result;
	}
};

export const GetFolderText = () => {
	var result = FolderText[getLanguage().code];

	if (result == null) {
		return FolderText[defaultLanguageCode];
	} else {
		return result;
	}
};

export const GetCreationText = () => {
	var result = CreationText[getLanguage().code];

	if (result == null) {
		return CreationText[defaultLanguageCode];
	} else {
		return result;
	}
};
