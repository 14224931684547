import React from "react";
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap";
import getLanguage, { setLanguage } from "../Language/LanguageHelper";

export class LanguageSelector extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpen: false,
			selected: "Dansk",
		};

		this.toggle = this.toggle.bind(this);
		this.select = this.select.bind(this);
	}

	componentDidMount() {
		var language = getLanguage();
		this.setState({ userLanguage: language, selected: language.name });
	}

	toggle() {
		this.setState({ isOpen: !this.state.isOpen });
	}

	select(Language, LaguageCode) {
		console.log(Language);
		console.log(LaguageCode);
		setLanguage(LaguageCode);
		this.setState({ selected: Language });
		window.location.reload();
	}
	render() {
		return (
			<div>
				<Dropdown isOpen={this.state.isOpen} toggle={this.toggle}>
					<DropdownToggle tag="button" type="button" className="form-control" caret>
						{this.state.selected}
					</DropdownToggle>
					<DropdownMenu>
						<DropdownItem onClick={() => this.select("Dansk", "da-DK")}>Dansk</DropdownItem>
						<DropdownItem onClick={() => this.select("English", "en-GB")}>English</DropdownItem>
						<DropdownItem onClick={() => this.select("Svenska", "se-SE")}>Svenska</DropdownItem>
						<DropdownItem onClick={() => this.select("Norsk", "nb-NO")}>Norsk</DropdownItem>
					</DropdownMenu>
				</Dropdown>
			</div>
		);
	}
}

export default LanguageSelector;
