import React, { Component } from "react";
import CustomDataList from "./../DataList/CustomDataList";
import { Spinner, SpinnerSize, MessageBar, MessageBarType, Link } from "@fluentui/react";
import { SearchStandardContent, GetStandardContentList } from "./../ApiClients/StandardContentClient";
import { GetSpinnerText, GetFileExploreDataListText } from "../Resources/Text";

export class StandardContentList extends Component {
  static displayName = StandardContentList.name;

  constructor(props) {
    super(props);
    this.state = {
      standardContentList: [],
      loading: true,
      path: "",
      searchValue: "",
      error: false,
      errorMessage: "",
    };

    this.fileSelected = this.fileSelected.bind(this);
    this.folderSelected = this.folderSelected.bind(this);
  }

  componentDidMount() {
    if (this.state.loading && this.state.error === false) {
      this.populatLetterData();
    }
  }

  fileSelected = (id) => {
    this.props.onSelection(id);
  };

  onSelection = (item) => {
    if (item.fileType === 0) {
      this.fileSelected(item.name);
    }
    if (item.fileType === 1) {
      this.folderSelected(item.name);
    }
  };

  folderSelected = (path) => {
    this.setState({ loading: true, path: path });
    console.log("SelectedPath", path);
    this.populatLetterData(path);
  };

  folderUp = () => {
    var pathArray = this.state.path.split("/");
    pathArray.pop();
    var newPath = pathArray.join("/");

    this.setState({ loading: true, path: newPath });
    console.log("FolderUp", newPath);
    this.populatLetterData(newPath);
  };
  serach = () => {
    console.log("Searching");
    this.searchStandardContent(this.state.searchValue);
  };

  clearSearch = () => {
    this.setState({ searchValue: "" });
    this.populatLetterData();
  };
  async searchStandardContent(searchValue) {
    SearchStandardContent(searchValue)
      .then((data) => {
        this.setState({ standardContentList: data, loading: false });
      })
      .catch((error) => console.log("Cant fetch data ", error));
  }

  handleChange = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  mapfilesAndDirectories(data) {
    const items = [];

    data.map((data, key) =>
      items.push({
        key: key,
        name: data.path,
        value: data.path,
        fileType: data.type,
        iconName: data.type === 0 ? this.FileIcon("docx").url : "https://static2.sharepointonline.com/files/fabric/assets/item-types/96/folder.png",
        description: data.description,
      })
    );
    return items;
  }

  FileIcon(docType) {
    return {
      docType,
      url: `https://static2.sharepointonline.com/files/fabric/assets/brand-icons/document/svg/${docType}_16x1.svg`,
    };
  }
  async populatLetterData(path) {
    GetStandardContentList(path)
      .then((data) => {
        if (data === null || data === undefined) {
          throw Error("No Standard Content  data");
        } else {
          this.setState({ standardContentList: this.mapfilesAndDirectories(data), loading: false });
        }
      })
      .catch((error) => {
        this.setState({ error: true, errorMessage: error.message });
        console.error("Cant fetch data ", error);
      });
  }

  render() {
    return (
      <div>
        {this.state.AccessDenied ? (
          <div>Access denied</div>
        ) : (
          <div>
            {this.state.error ? (
              <MessageBar messageBarType={MessageBarType.error} isMultiline={true} onDismiss={true} dismissButtonAriaLabel="Close">
                <span>{this.state.errorMessage}</span>
                <Link size="sm" color="link" onClick={() => window.location.reload()}>
                  Reload
                </Link>
              </MessageBar>
            ) : null}
            <CustomDataList loading={this.state.loading} path={this.state.path} onPathUp={() => this.folderUp()} data={this.state.standardContentList} onSelection={(e) => this.onSelection(e)} />
            {this.state.loading ? <Spinner label={GetSpinnerText().DefaultSpinnerText} size={SpinnerSize.large} ariaLive="assertive" labelPosition="right" /> : null}
          </div>
        )}
      </div>
    );
  }
}
