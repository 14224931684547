import da from 'date-fns/locale/da';
import enGB from 'date-fns/locale/en-GB';
import sv from 'date-fns/locale/sv';
import nn from 'date-fns/locale/nn';

const localStorageName = "Language";

const getLanguage = () => {
    var language = localStorage.getItem(localStorageName);
    var result;

    if (language == null) {
        const detectBrowserLanguage = require("detect-browser-language");

        language = detectBrowserLanguage();
        if (language.length > 0) {
            localStorage.setItem(localStorageName, language);

            result = SupportedLanguage.filter((x) => x.code === language);
        }
    } else {
        result = SupportedLanguage.filter((x) => x.code === language);
    }

    if (result.length === 0) {
        return SupportedLanguage.filter((x) => x.code === "en-GB")[0];
    } else {
        return result[0];
    }
};

const SupportedLanguage = [
    { code: "da-DK", name: "Dansk", local: da },
    { code: "en-GB", name: "English", local: enGB },
    { code: "se-SE", name: "Svenska", local: sv },
    { code: "nb-NO", name: "Norsk", local: nn },
];

export const setLanguage = (languageCode) => {
    localStorage.setItem(localStorageName, languageCode);
};

export default getLanguage;