import getLanguage from "../Language/LanguageHelper";

const defaultLanguageCode = "en-GB";

const CommonErrorMessage = {
	"en-GB": {
		HeadLine: "Oops!",
		Message: "Something went wrong. Try again or contact your administrator.",
	},
	"da-DK": {
		HeadLine: "Ups!",
		Message: "Noget gik galt. Prøv igen, eller kontakt din administrator.",
	},
	"se-SE": {
		HeadLine: "Hoppsan!",
		Message: "Något gick fel. Försök igen eller kontakta din administratör.",
	},

	"nb-NO": {
		HeadLine: "Beklager!",
		Message: "Noe gikk galt. Prøv på nytt eller kontakt administratoren din.",
	},
};

const PopUpMessage = {
	"en-GB": {
		Message: "Error opening popup window. This can happen if you are using IE or if popups are blocked in the browser. Try again",
	},
	"da-DK": {
		Message: "Fejl ved åbning af popop-vindue. Dette kan ske, hvis du bruger IE, eller hvis pop op-vinduer er blokeret i browseren. Prøv igen",
	},
	"se-SE": {
		Message: "Fel vid öppning av popup-fönster. Detta kan hända om du använder IE eller om popup-fönster är blockerade i webbläsaren. Försök igen",
	},

	"nb-NO": {
		Message: "Feil ved åpning av popup-vindu. Dette kan skje hvis du bruker IE eller hvis popup-vinduer er blokkert i nettleseren. Prøv igjen",
	},
};
export const GetCommonErrorMessage = () => {
	var result = CommonErrorMessage[getLanguage().code];

	if (result == null) {
		return CommonErrorMessage[defaultLanguageCode];
	} else {
		return result;
	}
};

export const GetPopUpErrorMessage = () => {
	var result = PopUpMessage[getLanguage().code];

	if (result == null) {
		return PopUpMessage[defaultLanguageCode];
	} else {
		return result;
	}
};
