import { PostRequest, GetRequest } from "./RequestHelper";

export const ValidateRequirements = async (path, fieldGroups, dataStore) => {
    let request = { path: path, fieldGroups: fieldGroups, dataStore: dataStore };
    return PostRequest("standardcontent/ValidateRequirements/", request);
};

export const CreateStandardContent = async (path, fieldGroups, contentFormat) => {
    let request = { path: path, fieldGroups: fieldGroups, format: contentFormat };
    return PostRequest("standardcontent/Create/", request);
};

export const GetRequirements = async (path, userId, dataStore) => {
    let request = { path: path, userInit: userId, dataStore: dataStore };
    return PostRequest("standardcontent/GetRequirements/", request);
};

export const SearchStandardContent = async (searchValue) => {
    var data = { value: searchValue };
    return PostRequest("StandardContent/SearchStandardContent/", data);
};

export const GetStandardContentList = async (path) => {
    var url = "StandardContent/StandardContent/";
    if (path !== undefined) {
        url = url + "?path=" + encodeURIComponent(path);
    }
    return GetRequest(url);
};