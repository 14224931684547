import authHelper from "./../Auth/AuthHelper";

export const PostRequest = async (url, data) => {
    return authHelper.getToken().then((token) => {
        return fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: !token ? {} : { Authorization: "Bearer " + token, "Content-Type": "application/json" },
            body: JSON.stringify(data),
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    return response.text();
                }
            })
            .then((resData) => {

                return resData;
            })

    });
};

export const PostRequestBlob = async (url, data) => {
    var filename = ""
    return authHelper.getToken().then((token) => {
        return fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: !token ? {} : { Authorization: "Bearer " + token, "Content-Type": "application/json" },
            body: JSON.stringify(data),
            responseType: "blob",
        })
            .then((response) => {
                if (response.status === 200) {
                    var disposition = response.headers.get(["content-disposition"])

                    //Resolve filename from header
                    filename = GetFilename(disposition)

                    return response.blob();
                } else {
                    throw Error(response.status);
                }
            }).then(b => { return { fileName: filename, blob: b } })
            .catch((x) => {
                console.log("Api load error", x);
            });
    });
};

export const GetRequest = async (url) => {
    return authHelper.getToken().then((token) => {
        return fetch(url, {
            headers: !token ? {} : { Authorization: "Bearer " + token },
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((resData) => {
                return resData;
            })
            .catch((x) => {
                console.log("Api load error", x);
            });
    });
};

const GetFilename = (disposition) => {
    var filename = ""
    try {
        if (disposition) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }
    }
    catch (ex) {
        console.error("Unable to resolve filename")
    }

    return filename
}