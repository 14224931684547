import React, { Component } from "react";
import StandardContentRequirements from "./../components/StandardContent/StandardContentRequirements";
import { StandardContentList } from "./../components/StandardContent/StandardContentList";
import AuthHelper from "./../components/Auth/AuthHelper";

export class StandardContent extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			standardContentId: "",
			standardContentSelected: false,
			value: "",
		};
	}

	async componentDidMount() {
		try {
			var username = await AuthHelper.getUserNameFromToken();
			var userId = username.substring(0, username.indexOf("@"));
			console.log("Token", userId);
			this.setState({ userId: userId });
		} catch (e) {
			console.log("Unable to read userId");
		}
	}

	selection = (id) => {
		this.setState({ standardContentId: id, standardContentSelected: true });
	};

	clearSelection = () => {
		this.setState({ standardContentId: "", standardContentSelected: false });
	};

	render() {
		return (
			<div>
				{this.state.standardContentSelected ? (
					<StandardContentRequirements isOfficeInitialized={this.props.isOfficeInitialized} onClearSelection={this.clearSelection} standardContentId={this.state.standardContentId} userId={this.state.userId} />
				) : (
					<StandardContentList onSelection={this.selection} />
				)}
			</div>
		);
	}
}
