import { GetRequest } from "./RequestHelper";
const baseUrl = "https://graph.microsoft.com/v1.0/groups/";
const tokenCacheKey = "token";

export const FileExists = async (fileName, groupId, channelRelativeUrl) => {
	if (channelRelativeUrl) {
		var folderName = channelRelativeUrl.substring(channelRelativeUrl.lastIndexOf("/"));

		var url = baseUrl + groupId + "/drive/root:" + folderName + ":/children?filter=name eq '" + fileName + "'";

		//Requst Accestoken from Usertoken
		var token = await GetTeamsToken();

		sessionStorage.setItem(tokenCacheKey, JSON.stringify(token));
		return await fetch(url, {
			method: "Get", // *GET, POST, PUT, DELETE, etc.
			mode: "cors", // no-cors, *cors, same-origin
			headers: !token ? {} : { Authorization: "Bearer " + token.access_token },
		})
			.then((response) => {
				console.log(response);
				if (response.status === 200 || response.status === 201) {
					return response;
				} else {
					//TODO:??
					return response;
				}
			})
			.then((response) => response.json())
			.then((j) => {
				if (j.value.length > 0) {
					return true;
				} else {
					return false;
				}
			})
			.catch((x) => {
				console.log("Api load error", x);
			});
	} else {
		console.log("no channelRelativeUrl");
		return true;
	}
};

export const UploadDocumentToGroup = async (fileName, contentType, groupId, channelRelativeUrl, fileBytes) => {
	var folderName = channelRelativeUrl.substring(channelRelativeUrl.lastIndexOf("/"));

	var url = baseUrl + groupId + "/drive/root:" + folderName + "/" + fileName + ":/content";

	//Requst Accestoken from cache
	var token = sessionStorage.getItem(tokenCacheKey);
	try {
		token = JSON.parse(token);
	} catch {
		console.log("Unable to decode cached Token");
	}

	if (token === undefined || token === null) {
		//Requst Accestoken from Usertoken
		token = await GetTeamsToken();
	}

	return await fetch(url, {
		method: "PUT", // *GET, POST, PUT, DELETE, etc.
		mode: "cors", // no-cors, *cors, same-origin
		headers: {
			Authorization: "Bearer " + token.access_token,
			"Content-Type": contentType,
		},
		body: fileBytes,
	})
		.then((response) => {
			console.log(response);
			if (response.status === 200 || response.status === 201) {
				return response;
			} else {
				//TODO:??
				return response;
			}
		})
		.then((response) => response.json())
		.then((j) => {
			return j;
		})
		.then(sessionStorage.removeItem(tokenCacheKey))
		.catch((x) => {
			console.log("Api load error", x);
		});
};

export const GetTeamsToken = async () => {
	return await GetRequest("teams/gettoken/");
};
