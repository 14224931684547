import React, { Component } from "react";
import { Dropdown, TextField, Label, DatePicker, DayOfWeek, mergeStyleSets, Text, Checkbox, ChoiceGroup } from "@fluentui/react";
import { format, parse } from "date-fns";
import RegExTextField from "./InputForms/RegExTextField";
import { GetDataPickerText } from "./Resources/Text";
import { ProfilePicker } from "./peoplepicker";
import getLanguage from "./Language/LanguageHelper";
import { GetProfilePickerText } from "./Resources/Text";

export class ControlGroup extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			actions: [],
			dropdownOpen: false,
		};
		this.toggle = this.toggle.bind(this);
		this.onInitialsChange = this.onInitialsChange.bind(this);
		this.change = this.change.bind(this);

		// Populer med default text v. første load
		this.props.control.fields.map((field, i) => {
			field.value = isEmptyOrSpaces(field.value) ? (isEmptyOrSpaces(field.defaultText) ? "" : field.defaultText) : field.value;
		});
	}
	static displayName = ControlGroup.name;

	change = (id, x) => {
		this.props.valueChanged({ id: id, value: x });
	};

	toggle(event) {
		this.setState({
			dropdownOpen: !this.state.dropdownOpen,
		});
	}

	mapoptions(x) {
		return x.options.map((option) => {
			var temp = new Object();
			temp.key = option;
			temp.text = option;
			return temp;
		});
	}

	onFormatDate = (date, dateformat) => {
		try {
			return format(date, dateformat, { locale: getLanguage().local });
		} catch {
			try {
				console.error("Unable to format date. Using default format");
				return format(date, "d. MMM yyyy");
			} catch {
				return format(Date.now(), "d. MMM yyyy");
			}
		}
	};
	_picker = React.createRef();

	renderSwitch(x, groupType) {
		switch (x.type) {
			case 1: {
				// Radiobuttons
				return (
					<ChoiceGroup
						options={this.mapoptions(x)}
						required={x.isMandatory}
						selectedKey={x.value}
						onChange={(e, item) => {
							this.change(x.id, item.key);
						}}
					/>
				);
			}

			case 2: {
				//Checkbox
				return (
					<Checkbox
						label={x.label}
						onChange={(e, value) => {
							this.change(x.id, value ? "true" : "false");
						}}
						checked={x.value === "true"}
					/>
				);
			}

			case 3: //KomboBox
				return (
					<Dropdown
						htmlFor={x.id + "control"}
						onChange={(e, item) => {
							this.change(x.id, item.key);
						}}
						selectedKey={x.value}
						isOpen={this.state.dropdownOpen}
						styles={this.dropdownStyles}
						toggle={this.toggle}
						options={this.mapoptions(x)}
					></Dropdown>
				);

			case 4: //DateField
				console.log(x);
				return (
					<DatePicker
						htmlFor={x.id + "control"}
						required={x.isMandatory}
						className={this.controlClass.control}
						firstDayOfWeek={DayOfWeek.Monday}
						strings={GetDataPickerText()}
						formatDate={(date) => this.onFormatDate(date, x.format)}
						placeholder="Select a date..."
						ariaLabel="Select a date"
						{...(x.value == null ? {} : { value: parse(x.value, x.format, new Date()) })}
						onSelectDate={(item) => {
							this.change(x.id, format(item, x.format));
						}}
					/>
				);

			case 0: //Textfield
				if (x.tag === "UserId") {
					//UserId field with attatched pepolepickerfield
					return (
						<div className={this.controlClass.control}>
							<Label className={this.controlClass.control} variant="small">
								{GetProfilePickerText().SearchLabel}
							</Label>
							<div className={this.controlClass.pepolePicker}>
								<ProfilePicker onInitialsChange={(initials) => this.onInitialsChange(initials)} className="pb-5" />
							</div>

							<TextField
								label={x.label}
								className={this.controlClass.control}
								id={x.id + "control"}
								variant="small"
								onChange={(e) => this.change(x.id, e.target.value)}
								value={x.value}
								required={x.isMandatory}
								multiline={x.multiLine ? 3 : 0}
								autoAdjustHeight
							/>
						</div>
					);
				} else if (x.validationExpression !== null && x.validationExpression.length > 0) {
					console.log(x)
					return (
						<RegExTextField
							regEx={x.validationExpression}
							className={this.controlClass.control}
							id={x.id}
							value={x.value}
							required={x.isMandatory}
							onChange={(value) => this.change(x.id, value)}
							errorMessage={x.validationErrorMessage}
							multiline={x.multiLine ? 3 : 0}
							autoAdjustHeight
						/>
					);
				} else {
					return <TextField className={this.controlClass.control} id={x.id + "control"} variant="small" onChange={(e) => this.change(x.id, e.target.value)} value={x.value} required={x.isMandatory} multiline={x.multiLine ? 3 : 0} autoAdjustHeight />;
				}
			default:
				return 0;
		}
	}

	dropdownStyles = {
		dropdown: { width: "100%", maxWidth: "300px" },
	};

	controlClass = mergeStyleSets({
		control: {
			margin: "0 0 0 0",
			maxWidth: "300px",
			minWidth: "100px",
		},
		pepolePicker: {
			margin: "0 0 0 0",
			maxWidth: "300px",
			paddingBottom: "5px",
			minWidth: "100px",
		},
	});

	onInitialsChange = (profile) => {
		this.props.onClearAllValues();

		if (profile.userInfo !== null && profile.userInfo !== undefined) {
			profile.userInfo.map((x) => {
				var control = this.props.control.fields.filter((field) => field.tag === x.tag);

				if (control.length > 0) {
					var id = control[0].id;
					this.change(id, x.fieldValue);
				}
				return true;
			});
		}
	};

	render() {
		return (
			<div>
				<Text id="tabelLabel" variant="large" nowrap block>
					{this.props.control.name}
				</Text>

				{this.props.control.fields.map((x) => (
					<div key={x.id} className={x.excludeFromDialog ? "d-none" : ""}>
						{
							//Dont show textlable for checkbox, but create padding
							x.type === 2 || x.tag === "UserId" ? (
								<div></div>
							) : (
								<Label variant="small" required={x.type !== 0 ? x.isMandatory : false} htmlFor={x.id + "control"}>
									{x.label}
								</Label>
							)
						}
						{this.renderSwitch(x)}
					</div>
				))}
			</div>
		);
	}
}
function isEmptyOrSpaces(str) {
	return str === null || str.match(/^ *$/) !== null;
}
export default ControlGroup;
