import React, { Component } from "react";
import { IconButton, Label, TooltipHost } from "@fluentui/react";
import { GetFileExploreDataListText } from "./Resources/Text";

export class Breadcrumbs extends Component {
    constructor(props, context) {
        super(props, context);                
    }

    returnKey = { iconName: 'ReturnKey' };

    getFormatedPath = () => {
        if (this.props.path) {
            var path = this.props.path;
            //Replace slash with unicode
            var result = path.split('/').join('\u23F5')

            //Ensure path stats with unicode
            if (result && result.length > 0) {
                if (result.startsWith('\u23F5') === false) {
                    result = '\u23F5' + result
                }
            }
            else {
                result = '\u23F5'
            }

            return result;
        } else {
            return '\u23F5'
        }
    }
    isDisabled = () => {
        if (this.props.path) {
            //Evaluate frome path length if the button is avtive
            return this.props.path.length <= 1
        } else {
            return true;
        }
    }

    render() {
        return (
            <div>
                <TooltipHost content={GetFileExploreDataListText().LevelUp}>
                    <IconButton style={{ "verticalAlign": "middle", "display": "inline-block" }} iconProps={this.returnKey} onClick={() => this.props.onClick()} disabled={this.isDisabled()} checked={false} />
                </TooltipHost>
                <Label style={{ "display": "inline-block" }}> {this.getFormatedPath()}</Label>
            </div>
        );
    }
}

export default Breadcrumbs;