import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { GetConfirmDialogText } from '../Resources/Text'

export class CustomDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: false
        };
    }

    dialogContentProps = {
        type: DialogType.normal,
        title: this.props.title,
        closeButtonAriaLabel: 'Close',
        subText: this.props.subText,
    };

    onClick = result => {
        this.props.result(result)

        this.setState({ hidden: true })
    }

    render() {
        return (<div>

            <Dialog
                hidden={this.state.hidden}

                dialogContentProps={this.dialogContentProps}

            >
                <DialogFooter>
                    <PrimaryButton onClick={() => this.props.result(true)} text={GetConfirmDialogText().Yes} />
                    <DefaultButton onClick={() => this.props.result(false)} text={GetConfirmDialogText().No} />
                </DialogFooter>
            </Dialog>

        </div>);
    }
}

export default CustomDialog;