class Connect365Helper {
    //Evalute if context is in Connect365
    isConnect365() {
        var isConnect365 = sessionStorage.getItem("isConnect365")
        if (isConnect365 == 'true' || isConnect365 == true) {
            return true
        }
        else {
            return false
        }
    }
}

export default Connect365Helper