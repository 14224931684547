import React, { Component } from "react";
import { GetLetters } from "../../components/ApiClients/LetterClient";
import { Spinner,SpinnerSize,MessageBar, Link, MessageBarType, initializeIcons } from "@fluentui/react";
import { GetSpinnerText, GetFolderText } from "../Resources/Text";
import CustomDataList from "../DataList/CustomDataList";

export class LetterList extends Component {
    static displayName = LetterList.name;

    constructor(props) {
        super(props);
        this.state = {
            letters: [],
            loading: true,
            error: false,
            errorMessage: "",
            spinnerText: GetSpinnerText().DefaultSpinnerText,
            path: ''
        };

        this.selected = this.selected.bind(this);
    }

    componentDidMount() {
        if (this.state.loading && this.state.error === false) {
            this.populatLetterData("");
        }
        initializeIcons();
    }

    selected = (item) => {
        if (item.fileType === "folder") {            
            this.setState({ letters: null, loading: true, path: item.value })
            this.populatLetterData(item.value)
        } else {
            this.props.onSelection(item.key);
        }
    };
    onFolderLevelUp = () => {        
        let path = this.state.path

        if (path.indexOf('/') >= 0) {

            //Pop last foldername
            var items = path.split('/').pop();

            //Create new path
            var newPath = path.substring(0, path.lastIndexOf(items) - 1)

            if (newPath.length === 0) {
                newPath = "/"
            }

            this.setState({ letters: null, loading: true, path: newPath })

            //Populate letterdata form new path
            this.populatLetterData(path)

        } else {
            this.setState({ letters: null, loading: true, path: "/" })

            //Populate letterdata form new path
            this.populatLetterData("")
        }

      
    }
    render() {
        return (
            <div>
                {this.state.AccessDenied ? (
                    <div>Access denied</div>
                ) : (
                        <div>
                            {this.state.error ?
                                <MessageBar messageBarType={MessageBarType.error} isMultiline={true} onDismiss={true} dismissButtonAriaLabel="Close">
                                    <span>{this.state.errorMessage}</span> <Link size="sm" color="link" onClick={() => window.location.reload()}> Reload </Link>
                                </MessageBar> : null}                         
                           
                            <CustomDataList loading={this.state.loading} data={this.state.letters} path={this.state.path} onPathUp={(() => this.onFolderLevelUp())} onSelection={(item) => this.selected(item)} />
                            {this.state.loading ?
                                <Spinner label={GetSpinnerText().DefaultSpinnerText} size={SpinnerSize.large} ariaLive="assertive" labelPosition="right" /> : null}
                        </div>
                    )}
            </div>
        );
    }

    populatLetterData(path) {
        var templateTypeFilter;
        var host = sessionStorage.getItem('Host')
        //Resolve CloudTemplateType from Host
        if (host) {
            if (host.toLowerCase() === "word")
                templateTypeFilter = 0
            else if (host.toLowerCase() === "excel")
                templateTypeFilter = 1
            else if (host.toLowerCase() === "powerpoint")
                templateTypeFilter = 2
        }

        //If templateTypeFilter is null all types are listed
        GetLetters(templateTypeFilter, path)
            .then((data) => {
                const items = [];
                if (data === null || data === undefined) {
                    throw new Error("Unable to read letter data");
                } else {
                    data.folders.map((data) => {
                        return items.push({
                            key: data.fullPath,
                            name: data.folderName,
                            value: data.fullPath,
                            fileType: "folder",
                            iconName: "https://static2.sharepointonline.com/files/fabric/assets/item-types/96/folder.png",
                            description: GetFolderText().Description,
                        });
                    });

                    data.letters.map((data) => {
                        return items.push({
                            key: data.id,
                            name: data.name,
                            value: data.name,
                            fileType: "file",
                            iconName: this.FileIcon(data.cloudTemplateType).url,
                            description: data.description,
                        });
                    });

                    return items;
                }
            })
            .then((data) => this.setState({ letters: data, loading: false }))
            .catch((error, info) => {
                console.log(error.message)
                console.log(info)
                this.setState({ error: true, errorMessage: error.message });
                console.log("Cant fetch data ", JSON.stringify(error));
            });
    }

    //Resolve filetype from "cloudTemplateType"
    FileType(cloudTemplateType) {
        switch (cloudTemplateType) {
            case 0:
                return "dotx";
            case 1:
                return "xltx";
            case 2:
                return "potx";
            case 3:
                return "folder";
            default:
        }
    }

    FileIcon(cloudTemplateType) {
        return {
            cloudTemplateType,
            url: `https://static2.sharepointonline.com/files/fabric/assets/brand-icons/document/svg/${this.FileType(cloudTemplateType)}_16x1.svg`,
        };
    }
}

export default LetterList;