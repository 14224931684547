import * as React from "react";

import { NormalPeoplePicker, ValidationState, mergeStyleSets } from "@fluentui/react";
import { SearchProfiles } from "./ApiClients/ProfileClient";
import { GetProfilePickerText } from "./Resources/Text";

const suggestionProps = {
    suggestionsHeaderText: GetProfilePickerText().SuggestionsHeaderText,
    mostRecentlyUsedHeaderText: GetProfilePickerText().MostRecentlyUsedHeaderText,
    noResultsFoundText: GetProfilePickerText().NoResultsFoundText,
    loadingText: GetProfilePickerText().LoadingText,
    showRemoveButtons: true,
    suggestionsAvailableAlertText: GetProfilePickerText().SuggestionsAvailableAlertText,
    suggestionsContainerAriaLabel: GetProfilePickerText().SuggestionsContainerAriaLabel,
};

const mru = [];

export const ProfilePicker = (e) => {
    const [currentSelectedItems, setCurrentSelectedItems] = React.useState([]);    
    const [isPickerDisabled] = React.useState(false);
    const [mostRecentlyUsed, setMostRecentlyUsed] = React.useState(mru);
    const [peopleList, setPeopleList] = React.useState([]);

    const picker = React.useRef(null);

    const onFilterChanged = async (filterText, currentPersonas, limitResults) => {
        console.log(suggestionProps)
        if (filterText.length >= 2) {
            return SearchProfiles(filterText, 10).then((profiles) => {
                let people = [];                
                if (profiles) {
                    profiles.forEach((profile) => {
                        if (people.filter((x) => x.imageInitials === profile.imageInitials).length === 0) {                            
                            people.push(profile);
                        }
                    });
                }
                return people
            }).catch(x =>
            {
                console.log("Unable to lookup profiles")
                return []
            });
        } else {
            return [];
        }
    };

    const returnMostRecentlyUsed = (currentPersonas) => {
        setMostRecentlyUsed(removeDuplicates(mostRecentlyUsed, currentPersonas));
        return mostRecentlyUsed;
    };

    const onRemoveSuggestion = (item) => {
        const indexPeopleList = peopleList.indexOf(item);
        const indexMostRecentlyUsed = mostRecentlyUsed.indexOf(item);

        if (indexPeopleList >= 0) {
            const newPeople = peopleList.slice(0, indexPeopleList).concat(peopleList.slice(indexPeopleList + 1));
            setPeopleList(newPeople);
        }

        if (indexMostRecentlyUsed >= 0) {
            const newSuggestedPeople = mostRecentlyUsed.slice(0, indexMostRecentlyUsed).concat(mostRecentlyUsed.slice(indexMostRecentlyUsed + 1));
            setMostRecentlyUsed(newSuggestedPeople);
        }
    };

    const onItemsChange = (items) => {
        if (items.length > 1) {
            items.shift();
        }
        setCurrentSelectedItems(items);

        if (items.length > 0) {
            e.onInitialsChange(items[0]);
        }
    };
    const controlClass = mergeStyleSets({
        control: {
            margin: "0 0 0 0",
            maxWidth: "300px",
        },
    });

    return (
        <div>
            <NormalPeoplePicker
                defaultValue="test"
                className={controlClass.control}
                onResolveSuggestions={onFilterChanged}
                onEmptyInputFocus={returnMostRecentlyUsed}
                getTextFromItem={getTextFromItem}
                pickerSuggestionsProps={suggestionProps}
                key={"normal"}
                selectedItems={currentSelectedItems}
                onChange={onItemsChange}
                onRemoveSuggestion={onRemoveSuggestion}
                onValidateInput={validateInput}
                removeButtonAriaLabel={"Remove"}
                inputProps={{
                    "aria-label": "People Picker",
                    placeholder: currentSelectedItems.length === 0 ? GetProfilePickerText.Placeholder : null,
                    style: { " maxWidth": "300px" },
                }}
                componentRef={picker}
                onInputChange={onInputChange}
                resolveDelay={300}
                disabled={isPickerDisabled}
            />
        </div>
    );
};

function removeDuplicates(personas, possibleDupes) {
    return personas.filter((persona) => !listContainsPersona(persona, possibleDupes));
}

function listContainsPersona(persona, personas) {
    if (!personas || !personas.length || personas.length === 0) {
        return false;
    }
    return personas.filter((item) => item.text === persona.text).length > 0;
}

function getTextFromItem(persona) {
    return persona.text;
}

function validateInput(input) {
    if (input.indexOf("@") !== -1) {
        return ValidationState.valid;
    } else if (input.length > 1) {
        return ValidationState.warning;
    } else {
        return ValidationState.invalid;
    }
}

/**
 * Takes in the picker input and modifies it in whichever way
 * the caller wants, i.e. parsing entries copied from Outlook (sample
 * input: "Aaron Reid <aaron>").
 *
 * @param input The text entered into the picker.
 */
function onInputChange(input) {
    const outlookRegEx = /<.*>/g;
    const emailAddress = outlookRegEx.exec(input);

    if (emailAddress && emailAddress[0]) {
        return emailAddress[0].substring(1, emailAddress[0].length - 1);
    }

    return input;
}