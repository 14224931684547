import React, { Component } from "react";
import { TextField } from "@fluentui/react";

export class RegExTextField extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      maxLength: this.props.maxLenght == null ? 0 : props.maxLength,
      required: this.props.required == null ? false : props.required,
      value: this.props.value == null ? "" : props.value,
      regEx: this.props.regEx == null ? "" : props.regEx,
      errorMessage: "",
    };
  }

  componentDidMount() {
    // Execute regex validation if mounted with value
    if (this.props.value) {
      this.validate(this.props.value);
    }
  }

  componentDidUpdate(prevProps) {
    // Execute regex validation if value is changed
    if (this.props.value && this.props.value !== prevProps.value) {
      this.validate(this.props.value);
    }
  }

  onChange = (newValue) => {
    if (newValue && newValue != this.props.value) {
      this.validate(newValue);
      this.props.onChange(newValue);
    } else {
      this.props.onChange(null);
      this.setState({ value: null, errorMessage: null });
    }
  };

  validate = (newValue) => {
    let regex = new RegExp(this.state.regEx);

    if (regex.test(newValue)) {
      this.setState({ value: newValue, errorMessage: "" });
    } else {
      // This block should NOT be necessary, but there's currently a bug (#1350) where a controlled
      // TextField will continue to accept input even if its `value` prop isn't updated.
      // (The correct behavior is that the displayed value should *always* match the `value` prop.
      // If the `value` prop isn't updated in response to user input, the input should be ignored.)
      // Because this is a large behavior change, the bug won't be fixed until Fabric 7.
      // As a workaround, force re-rendering with the existing value.
      this.setState({ value2: this.state.value2, value: newValue, errorMessage: this.props.errorMessage });
    }
  };

  render() {
    return <TextField className={this.props.className} errorMessage={this.state.errorMessage} variant="small" onChange={(e) => this.onChange(e.target.value)} value={this.props.value == null ? "" : this.props.value} required={this.state.required} />;
  }
}

export default RegExTextField;
