import React, { useState } from "react";
import { ActionButton, getTheme, mergeStyleSets, FontWeights, Modal, initializeIcons, MessageBar, MessageBarType } from "@fluentui/react";

const CustomInfoModal = (props) => {
	initializeIcons();
	const { showModal, onClose, header, message, errorMessage, headerColor } = props;

	const [modal, setModal] = useState(showModal);
	const theme = getTheme();

	const GetHeaderColor = () => {

		if (props.headerColor) {
			return headerColor
		}
		else {
			return theme.palette.themePrimary
        }
		
    }

	const contentStyles = mergeStyleSets({
		container: {
			display: "flex",
			flexFlow: "column nowrap",
			alignItems: "stretch",
		},
		header: [
			// tslint:disable-next-line:deprecation
			theme.fonts.xLargePlus,
			{
				flex: "1 1 auto",
				borderTop: `4px solid ${GetHeaderColor()}`,
				color: theme.palette.neutralPrimary,
				display: "flex",
				alignItems: "center",
				fontWeight: FontWeights.semibold,
				padding: "12px 12px 14px 24px",
			},
		],
		body: {
			flex: "4 4 auto",
			padding: "0 24px 24px 24px",
			overflowY: "hidden",
			selectors: {
				p: { margin: "14px 0" },
				"p:first-child": { marginTop: 0 },
				"p:last-child": { marginBottom: 0 },
			},
		},
	});

	const onOkClick = () => {
		setModal(!modal);
		onClose();
	};

	const addFriendIcon = { iconName: "Accept" };

	return (
		<div>
			<Modal isOpen={showModal} isBlocking={true} dragOptions={false}>
				<div className={contentStyles.header}>
					<span id={"test"}>{header}</span>
				</div>
				<div className={contentStyles.body}>
					<p>{message}</p>

					<ActionButton onClick={() => onOkClick()} className="mt-1 mb-1 float-right" iconProps={addFriendIcon} allowDisabledFocus>
						Ok
					</ActionButton>

					{errorMessage.length > 0 ? (
						<div className="pt-1">
							<MessageBar messageBarType={MessageBarType.error} isMultiline={false} onDismiss={() => {}} dismissButtonAriaLabel="Close">
								{errorMessage}
							</MessageBar>
						</div>
					) : null}
				</div>
			</Modal>
		</div>
	);
};

export default CustomInfoModal;
