import { UserAgentApplication } from "msal";
import jwt from "jwt-decode";
import { GetClientSettings } from "../ApiClients/SettingsClient";
/* global Office, Date*/

const config = GetClientSettings();

const userAgentApplication = new UserAgentApplication({
	auth: {
		clientId: config.dtApiClientId,
		authority: config.authority,
		navigateToLoginRequestUrl: false,
		redirectUri: window.location.origin + "/function",
	},
	cache: {
		cacheLocation: "localStorage",
		storeAuthStateInCookie: false,
		redirectUri: window.location.origin + "/function",
	},
});

export class AuthHelper {
	_isAuthenticated = false;
	_user = null;
	_error = null;
	_token = "";

	constructor() {
		userAgentApplication.handleRedirectCallback(this.authCallback);
		var user = userAgentApplication.getAccount();
		this._isAuthenticated = user !== null;
		this._user = user;
	}

	//Evaluate if the context loaded from Connect365
	isConnect365 = () => {
		var value = sessionStorage.getItem("isConnect365");
		if (value === "true") return true;
		else return false;
	};

	async isAuthenticated(isOfficeLoaded) {
		window.onstorage = () => {
			console.log("stroage");
		};

		if (this.isOfficeTokenExpired()) {
			// If office is loades wait for token.
			// OBS SSO is not supportet in Connect 365

			var token = await authHelper.getToken(isOfficeLoaded);

			return token !== null;
		} else {
			return true;
		}
	}

	isOfficeTokenExpired() {
		var expires = localStorage.getItem("officeTokenExpires");

		if (Date.now() < expires) {
			return false;
		} else {
			return true;
		}
	}

	authCallback = async (error, response) => {
		var user = response.account;
		this._isAuthenticated = user !== null;
		this._user = user;
		console.log(error);
	};

	userAccount = () => {
		return userAgentApplication.getAccount();
	};

	login = async () => {
		try {
			//Using redirectlogin as default

			//Word online only support support popuplogin
			//Word native only supoorts redirect login
			var platform = sessionStorage.getItem("Platform");

			if (platform === "OfficeOnline") {
				return userAgentApplication
					.loginPopup({
						scopes: config.scopes,
					})
					.then((x) => {
						var user = x.account;
						this._user = user;
						this._isAuthenticated = user !== null;
						localStorage.removeItem("msal.error");
						return x;
					});
			} else {
				var redirectSufix = sessionStorage.getItem("redirectSufix");
				var redirectUri = window.location.origin + redirectSufix;

				userAgentApplication.handleRedirectCallback(this.authCallback);

				return userAgentApplication
					.loginRedirect({
						redirectUri: redirectUri,
						scopes: config.scopes,
					})
					.catch((x) => {
						//Fallback to popup login
						userAgentApplication.loginPopup({ scopes: config.scopes });
					});
			}
		} catch (err) {
			var error = {};

			console.log(err);

			if (typeof err === "string") {
			} else {
				error = {
					message: err.message,
					debug: JSON.stringify(err),
				};
			}
			this._isAuthenticated = false;
			this._user = {};
			this._error = error;
		}
	};

	logout() {
		userAgentApplication.logout();
	}

	sleep = async (ms) => {
		return new Promise((resolve) => setTimeout(resolve, ms));
	};

	getToken = async (OfficeJsLoaded) => {
		//Load officetoken from storage
		var token = localStorage.getItem("officeToken");

		//Check token not exist or app is running outside Office or in Connect365
		if ((!OfficeJsLoaded || this.isConnect365()) && (token === null || token === "")) {
			return await this.userAzureToken();
		} else {
			try {
				var created = localStorage.getItem("officeTokenExpires");

				var now = Date.now();

				if (now < created) {
					console.log("Using Office Token");
					return token;
				} else {
					token = await this.userOfficeToken(token);

					if (token) return token;
					else return await this.userAzureToken();
				}
			} catch (e) {
				console.log("Fallback to Azure Token");
				return await this.userAzureToken();
			}
		}
	};

	getUserNameFromToken = async () => {
		var token = await this.getToken();

		try {
			var decoded = jwt(token);
			return decoded.unique_name;
		} catch {
			console.log("Unable resolve username");
		}
	};

	userAzureToken = async () => {
		//Get azureToken from storage
		var azureToken = localStorage.getItem("azureToken");

		try {
			if (jwt(azureToken).exp < Date.now() / 1000) {
				console.log("azureToken expired");
				azureToken = null;
				localStorage.removeItem("azureToken");
			}
		} catch {}

		if (azureToken === null || azureToken === "null") {
			//Check out if token request allready is in progress
			if (await userAgentApplication.getAcquireTokenInProgress()) {
				console.log("In progress");
			} else {
				let request = { scopes: config.scopes, authority: config.authority };

				console.log("Silent token request");

				//Request token silent
				azureToken = await userAgentApplication
					.acquireTokenSilent(request)
					.then(function (account) {
						return account.accessToken;
					})
					.then((this._isAuthenticated = true))
					.catch(async (x) => {
						userAgentApplication.setAcquireTokenInProgress(false);
						console.log("logging in :" + x);

						try {
							await this.login();
							return this.userAzureToken();
						} catch (err) {
							console.log(err);
						}
						return null;
					});

				//Update token request status
				await userAgentApplication.setAcquireTokenInProgress(false);

				//Cache token in storage
				localStorage.setItem("azureToken", azureToken);
			}
		}

		return azureToken;
	};

	userOfficeToken = async () => {
		console.log("New Office token requested");

		var now = Date.now();

		var tokenRequest = '{"message":"token"}';

		try {
			//Request new token
			Office.context.ui.messageParent(tokenRequest);
		} catch {
			console.error("Token request failed");
			localStorage.removeItem("officeToken");
			throw Error("Token request failed");
		}

		var i = 0;
		//Wait for new token to be written in storage
		console.log("Wating for token");
		var error = localStorage.SSOError;
		while (now > localStorage.getItem("officeTokenExpires") && i < 10 && !error) {
			i = i + 1;
			error = localStorage.SSOError;
			await this.sleep(300);
		}

		if (!error && localStorage.getItem("officeToken")) {
			this._isAuthenticated = true;

			//Get token from storage
			return localStorage.getItem("officeToken");
		} else {
			return null;
		}
	};
}
const authHelper = new AuthHelper();

export default authHelper;
