import React, { Component } from "react";
import { GetRequirements, ValidateRequirements, CreateStandardContent } from "./../ApiClients/StandardContentClient";
import WordHelper from "./../../utils/WordHelper";
import ControlGroupWizard from "./../ControlGroupWizard";
import { Spinner, SpinnerSize, MessageBar, Link, MessageBarType } from "@fluentui/react";
import { GetSpinnerText, GetEnclosureInsertDialogText, GetAttachmentsInsertDialog } from "../Resources/Text";
import CustomDialog from "../Dialogs/CustomDialog";
import dialogService from "../Dialogs/dialogService";
import Connect365Helper from "../../utils/Connect365Helper";

export class FetchStandardContentRequirementsData extends Component {
    constructor(props) {
        super(props);

        var isConnect365 = new Connect365Helper().isConnect365();
        this.state = {
            controlgroups: [],
            hideControlWizard: true,
            spinnerText: GetSpinnerText().DefaultSpinnerText,
            errorMessage: null,
            insertEnclosure: false,
            showEnclosureDialog: false,
            standardContentData: null,
            isConnect365: isConnect365,
        };
    }

    componentDidMount() {
        this.populatStandardContentData();
    }

    //Request requirements
    populatStandardContentData = () => {
        var datastore = sessionStorage.getItem("DataStore");
        console.log(this.props.standardContentId);
        console.log(this.props.userId);
        console.log(datastore);
        GetRequirements(this.props.standardContentId, this.props.userId, datastore)
            .then((data) => {
                //If data has fields
                let isArray = data.constructor === Array;
                if (data && isArray && data.length > 0) {

                    const allFieldsDropdownWithOneOption = data.every((x) =>
                        x.fields.every((q) => q.type === 3 && q.options.length === 1)
                    );

                    if (allFieldsDropdownWithOneOption) {
                        this.validateGroup(data);
                    } else {
                        //Show wizard
                        this.setState({ controlgroups: data, hideControlWizard: false });
                    }




                } else {
                    if (data && isArray) {
                        //Standardcontent with no fields
                        this.validateGroup(data);
                    } else {
                        //Invalid file

                        throw new Error(data);
                    }
                }
            })
            .catch((error) => {
                this.setState({ errorMessage: error.message });
            });
    };

    //Server side group validation
    validateGroup = (groups) => {
        groups.forEach((group) => {
            group.fields.forEach((field) => {
                field.value = field.value?.replace("\n", "\v");
            })
        })
        return ValidateRequirements(this.props.standardContentId, groups)
            .then((data) => {
                console.log(data);
                if (data.allValid) {
                    //Hide wizard
                    this.setState({ hideControlWizard: true });

                    //Start generate stadardcontent content/document
                    this.generateStandardContent(data.fieldGroups);
                    return data;
                } else {
                    const allFieldsDropdownWithOneOption = data.fieldGroups.every((x) =>
                        x.fields.every((q) => q.type === 3 && q.options.length === 1)
                    );

                    //Return data set if new requirements is received or dataset contains invalid fieldvalues
                    if (allFieldsDropdownWithOneOption) {
                        return this.validateGroup(data.fieldGroups);
                    } else {
                        //Show wizard
                        this.setState({ hideControlWizard: false });
                        return data;
                    }
                    
                }
            })
            .catch((error) => {
                console.log("Cant fetch data ", error);
                this.setState({ errorMessage: "Unable to fetch data - Validate source" });
            });
    };

    generateStandardContent = async (fieldGroups) => {
        CreateStandardContent(this.props.standardContentId, fieldGroups, this.getContentFormat())
            .then(async (data) => {
                if (data) {
                    //If response contains addendums/attachments
                    if (typeof data.addendums !== "undefined" && data.addendums && data.addendums.length > 0) {
                        //Create list of addendumnames
                        var addendumList = "";
                        data.addendums.map((addendum) => (addendumList = addendumList + "\n\r-" + addendum.name));

                        //Wait for dialog response
                        const result = await dialogService.show(GetAttachmentsInsertDialog().Title, GetAttachmentsInsertDialog().Subtext, addendumList);
                    }

                    //If response contains enclosure/appendix
                    if (typeof data.enclosure !== "undefined" && data.enclosure) {
                        //Show dialog with enclosure message
                        this.setState({ showEnclosureDialog: true, standardContentData: data, hideControlWizard: false });
                    } else {
                        //Insert data
                        this.handleDataInsert(data);
                    }
                } else {
                    console.log("No data to insert");
                    throw "No data to insert";
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({ errorMessage: "Standardcontent creation failed", hideControlWizard: false });
            });
    };

    getContentFormat = () => {
        if (this.state.isConnect365) {
            return 2; //HTML file
        } else {
            return 1; // OOxml
        }
    };

    //Insert data in document
    handleDataInsert = (data) => {
        if (data) {
            this.setState({ hideControlWizard: true, spinnerText: GetSpinnerText().Inserting });
            if (this.state.isConnect365) {
                WordHelper.InsertHtml(data);
            } else {
                WordHelper.InsertOoxml(data);
            }
        } else {
            console.error("OOXML item is empty");
        }
    };

    onDialogResponse = (insertEnclosure) => {
        //Hide enclosure dialog
        this.setState({ showEnclosureDialog: false, hideControlWizard: false });

        var data = this.state.standardContentData;
        if (insertEnclosure === false) {
            //Remove enclosure from standardContentData
            data.enclosure = "";
        }

        this.handleDataInsert(data);
    };

    onClearSelection = () => {
        //Clear selection of standardcontent
        this.props.onClearSelection();
    };

    onDataReady = (controlgoups) => {
        console.log(controlgoups);
    };

    render() {
        return (
            <div>
                {this.state.errorMessage ? (
                    <MessageBar messageBarType={MessageBarType.error} isMultiline={true} onDismiss={true} dismissButtonAriaLabel="Close">
                        <span>{this.state.errorMessage}</span>{" "}
                        <Link size="sm" color="link" onClick={() => window.location.reload()}>
                            {" "}
                            Reload{" "}
                        </Link>
                    </MessageBar>
                ) : null}

                {this.state.hideControlWizard ? (
                    <Spinner label={this.state.spinnerText} size={SpinnerSize.large} SpinnerSize={SpinnerSize.large} ariaLive="assertive" labelPosition="right" />
                ) : (
                    <ControlGroupWizard controlgroups={this.state.controlgroups} onClearSelection={this.onClearSelection} onValidateGroup={(controlgroups) => this.validateGroup(controlgroups)} onDataReady={(controlgrups) => this.onDataReady(controlgrups)} />
                )}

                {this.state.showEnclosureDialog ? (
                    <div>
                        <CustomDialog title={GetEnclosureInsertDialogText().Title} subText={GetEnclosureInsertDialogText().Subtext} result={(x) => this.onDialogResponse(x)}></CustomDialog>
                    </div>
                ) : null}
            </div>
        );
    }
}
export default FetchStandardContentRequirementsData;
