import React, { Component } from "react";
import { Route } from "react-router";
import { Layout } from "./components/Layout";
import { Auth } from "./components/Auth/Auth";
import { StandardContent } from "./views/StandardContent";
import "./custom.css";
import authHelper from "./components/Auth/AuthHelper";
import { Letters } from "./views/Letters";
import Profile from "./views/Profile";
import { Button, Spinner, SpinnerSize, Link, MessageBarButton, MessageBarType, MessageBar } from "@fluentui/react";
import { GetSpinnerText } from "./components/Resources/Text";
import { Row, Col } from "reactstrap";
import Login from "./views/Teams/Login";
import Landing from "./views/Teams/Landing";
import TabSettings from "./views/Teams/TabSettings";
import { Unsupported } from "./views/Unsupported";
import { GetPopUpErrorMessage } from "./components/Resources/ErrorMessages";
const microsoftTeams = require("@microsoft/teams-js");

export default class App extends Component {
	static displayName = App.name;

	constructor(props) {
		super(props);

		this.state = {
			isAuthenticated: false,
			loading: true,
			spinnerText: GetSpinnerText().SignIn,
			hideNavBar: false,
		};

		this.loginTeamsSucess = this.loginTeamsSucess.bind(this);
		this.loginTeams = this.loginTeams.bind(this);
	}

	async componentDidMount() {
		var isTeams = sessionStorage.getItem("Platform") === "Teams";
		if (!this.state.isAuthenticated) {
			var result = await authHelper.isAuthenticated(this.props.isOfficeInitialized);
			this.setState({ isAuthenticated: result, loading: false, isTeams: isTeams });
		}
	}

	login = async () => {
		authHelper
			.login()
			.then((x) => {
				this.setState({ isAuthenticated: true });
			})
			.catch((x) => console.log(x));
	};

	loginTeams = () => {
		microsoftTeams.initialize();
		microsoftTeams.authentication.authenticate({
			url: window.location.origin + "/teams/login",
			width: 600,
			height: 535,
			successCallback: this.loginTeamsSucess,
			failureCallback: function (reason) {},
		});
	};

	loginTeamsSucess(x) {
		this.setState({ isAuthenticated: true });
	}

	getMsalPopUpMessage = () => {
		if (localStorage.getItem("msal.error") === "popup_window_error") {
			return (
				<div className="pt-5">
					<MessageBar messageBarType={MessageBarType.warning} isMultiline={false} dismissButtonAriaLabel="Close">
						{GetPopUpErrorMessage().Message}
					</MessageBar>
				</div>
			);
		}
	};

	render() {
		let loginform = (
			<Row>
				<Col>
					{" "}
					<div className="box">
						<div className="centered">
							<div>
								{this.state.isTeams ? (
									<Button color="primary" onClick={this.loginTeams.bind(this)}>
										login (Teams)
									</Button>
								) : (
									<div>
										<div style={{ position: "absolute", left: "45%" }}>
											<Button color="primary" onClick={this.login.bind(this)}>
												Login
											</Button>
										</div>
										{this.getMsalPopUpMessage()}
									</div>
								)}
							</div>
						</div>
					</div>
				</Col>
			</Row>
		);

		const PrivateRoute = ({ component: Component, ...rest }) => (
			<Route
				{...rest}
				render={(props) => {
					return this.state.isAuthenticated ? <Component {...props} /> : <div>{loginform}</div>;
				}}
			/>
		);
		return (
			<div>
				{this.state.loading ? (
					<div className="box">
						<div className="centered">
							<Spinner label={this.state.spinnerText} size={SpinnerSize.large} SpinnerSize={SpinnerSize.large} ariaLive="assertive" labelPosition="right" />
						</div>
					</div>
				) : (
					<div>
						<Layout hideNavBar={this.state.hideNavBar} isOfficeInitialized={this.props.isOfficeInitialized} isTeamsInitialized={this.props.isTeamsInitialized}>
							{
								//Stand alone routes
							}
							<Route path="/auth" component={(...props) => <Auth {...props} />} />
							<PrivateRoute exact path="/" component={() => <Letters />} />
							<PrivateRoute exact path="/letter" component={() => <Letters />} />
							<PrivateRoute path="/standardcontent" component={() => <StandardContent />} />
							<PrivateRoute path="/profile" component={() => <Profile />} />
							<Route path="/unsupported" component={() => <Unsupported />} />

							{
								//Office routes
							}
							<Route path="/office/auth" component={(...props) => <Auth {...props} />} />
							<PrivateRoute exact path="/office" component={() => <Letters isOfficeInitialized={this.props.isOfficeInitialized} />} />
							<PrivateRoute exact path="/office/letter" component={() => <Letters isOfficeInitialized={this.props.isOfficeInitialized} />} />
							<PrivateRoute path="/office/standardcontent" component={() => <StandardContent isOfficeInitialized={this.props.isOfficeInitialized} />} />
							<PrivateRoute path="/office/profile" component={() => <Profile token={this.props.officeToken} />} />
							<Route path="/office/unsupported" component={() => <Unsupported token={this.props.officeToken} />} />

							{
								//Teams routes
							}
							<Route path="/teams/Landing" component={(...props) => <Landing {...props} />} />
							<Route path="/teams/login" component={(...props) => <Login {...props} />} />
							<Route path="/teams/tab" component={() => <TabSettings isTeamsInitialized={this.props.isTeamsInitialized} />} />
							<PrivateRoute path="/teams/letter" component={() => <Letters isTeamsInitialized={this.props.isTeamsInitialized} />} />
							<PrivateRoute exact path="/teams" component={() => <Letters isTeamsInitialized={this.props.isTeamsInitialized} />} />

							{
								//Extender routes
							}
							<Route path="/integration/auth" component={(...props) => <Auth {...props} />} />
							<PrivateRoute exact path="/integration" component={() => <Letters isIntegration={true} isOfficeInitialized={this.props.isOfficeInitialized} />} />
							<PrivateRoute exact path="/integration/letter" component={() => <Letters isIntegration={true} isOfficeInitialized={this.props.isOfficeInitialized} />} />
							<PrivateRoute path="/integration/standardcontent" component={() => <StandardContent isIntegration={true} isOfficeInitialized={this.props.isOfficeInitialized} />} />
							<PrivateRoute path="/integration/profile" component={() => <Profile isIntegration={true} token={this.props.officeToken} />} />
						</Layout>
					</div>
				)}
			</div>
		);
	}
}
