import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { UserAgentApplication } from "msal";
import { GetClientSettings } from "./components/ApiClients/SettingsClient";
const microsoftTeams = require("@microsoft/teams-js");
/* global Office */

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
microsoftTeams.initialize();

let isOfficeInitialized = false;
let isTeamsInitialized = false;

let render = (Component, Token) => {
    let officeToken = Token.length > 0 ? Token : "";
    handleMetadata()
    ReactDOM.render(
        <BrowserRouter basename={baseUrl}>
            <Component isTeamsInitialized={isTeamsInitialized} isOfficeInitialized={isOfficeInitialized} officeToken={officeToken} />
        </BrowserRouter>,
        rootElement
    );
};

var href = window.location.href;

const getHashParameters = () => {
    let hashParams = {};
    window.location.hash
        .substr(1)
        .split("&")
        .forEach(function (item) {
            let s = item.split("="),
                k = s[0],
                v = s[1] && decodeURIComponent(s[1]);
            hashParams[k] = v;
        });
    return hashParams;
};
function getParameterByName(name) {
    var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

//Handel metadata from Extender
function handleMetadata() {
    //Get metadata from query
    var metadata = getParameterByName('metadata');
    if (metadata) {
        //Url decode string
        var uri_dec = decodeURIComponent(metadata);
        //Base64 decode string
        var decodedString = atob(uri_dec);
        //Save data in sesseion storage
        sessionStorage.setItem('metadata', decodedString)
    }
}

async function waitForOfficeJS() {
    while (window.Office === undefined || window.Office === null) {
        console.log("Waiting for Office.js")
        await new Promise(r => setTimeout(r, 200));
    }
    Office.initialize = function () { };
}

function clearPlatforminfo() {
    sessionStorage.removeItem("Platform")
    sessionStorage.removeItem("Host");
}

if (!href.includes("access_token")) {
    clearPlatforminfo();

    if (href.includes("teams")) {
        try {
            microsoftTeams.getContext(function (context) {
                if (context) {
                    console.log("teamSitePath", context.teamSitePath);
                    console.log("teamSiteUrl", context.teamSiteUrl);
                    console.log("channelRelativeUrl", context.channelRelativeUrl);
                    console.log("channelName", context.channelName);
                    console.log("channelType", context.channelType);
                    console.log("teamId", context.appSessionId);
                    console.log("context", context);
                    isTeamsInitialized = true;
                    sessionStorage.setItem("Platform", "Teams");

                    //Evaluate if app is loaded as personal tab or channel tab
                    if (context.channelType) {
                        sessionStorage.setItem("isInChannel", true);
                    } else {
                        sessionStorage.setItem("isInChannel", false);
                    }

                    var authTokenRequest = {
                        successCallback: function (result) {
                            console.log("Success: " + result);

                            localStorage.setItem("azureToken", result);

                            render(App, "");
                        },
                        failureCallback: function (error) {
                            console.log("Failure: " + error);
                            render(App, "");
                        },
                    };
                    microsoftTeams.authentication.getAuthToken(authTokenRequest);
                }
            });
        } catch { }
    } else if (href.includes("office")) {
        //Wait for Office.js script
        waitForOfficeJS().then(x => {
            //When Office.js is ready
            window.Office.onReady()
                .then((result) => {
                    //Resolve Office Host
                    if (result.host == null) {
                        isOfficeInitialized = false;
                        sessionStorage.removeItem("OfficeJsLoaded");
                    } else {
                        let { host, platform } = result;
                        sessionStorage.setItem("OfficeJsLoaded", "true");
                        console.log("Host", host);
                        console.log("Platform", platform);
                        sessionStorage.setItem("Host", host);
                        sessionStorage.setItem("Platform", platform);
                        isOfficeInitialized = true;
                        sessionStorage.setItem("redirectSufix", "/office")
                    }
                    render(App, "");
                })
                .catch((x) => {
                    console.log("Officejs not loaded", x);
                    sessionStorage.setItem("OfficeJsLoaded", "false");
                    render(App, "");
                });
        });
    } else {
        if (href.includes("/integration")) {
            sessionStorage.setItem("redirectSufix", "/integration")
        } else {
            sessionStorage.setItem("redirectSufix", "")
        }
        localStorage.setItem("OfficeJsLoaded", "false");

        render(App, "");
    }
}
// The entry point to your application
else if (href.includes("access_token") && href.includes("landing")) {
    console.log("msteams");
    let hashParams = getHashParameters();

    if (hashParams["error"]) {
        // Authentication/authorization failed
        microsoftTeams.authentication.notifyFailure(hashParams["error"]);
    } else if (hashParams["access_token"]) {
        // Get the stored state parameter and compare with incoming state
        // This validates that the data is coming from Azure AD
        let expectedState = localStorage.getItem("simple.state");
        if (expectedState !== hashParams["state"]) {
            // State does not match, report error
            microsoftTeams.authentication.notifyFailure("StateDoesNotMatch");
        } else {
            let key = "simple.result";
            localStorage.setItem(
                key,
                JSON.stringify({
                    idToken: hashParams["id_token"],
                    accessToken: hashParams["access_token"],
                    tokenType: hashParams["token_type"],
                    expiresIn: hashParams["expires_in"],
                })
            );
            localStorage.setItem("azureToken", hashParams["access_token"]);
            sessionStorage.setItem("Platform", "Teams");

            // Success: return token information to the tab
            microsoftTeams.authentication.notifySuccess({
                idToken: hashParams["id_token"],
                accessToken: hashParams["access_token"],
                tokenType: hashParams["token_type"],
                expiresIn: hashParams["expires_in"],
            });
        }
    } else {
        // Unexpected condition: hash does not contain error or access_token parameter
        microsoftTeams.authentication.notifyFailure("UnexpectedFailure");
    }

    isOfficeInitialized = false;
} else {
    let settings = GetClientSettings();
    let config = {
        auth: {
            clientId: settings.dtApiClientId,
            authority: settings.authority,
            redirectUri: window.location.origin,
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: true,
        },
    };

    let context = new UserAgentApplication(config);

    context.handleRedirectCallback((error, response) => {
        console.error(`MSAL Frame error: ${error}`);

        console.error(`MSAL Frame error: ${response}`);
    });
}