import React, { Component } from "react";
import { ControlGroup } from "./ControlGroup";
import { Spinner, SpinnerSize, PrimaryButton, DefaultButton, MessageBar, MessageBarType, Link } from "@fluentui/react";
import { Row, Col } from "reactstrap";
import { GetControlGroupWizardText, GetSpinnerText } from "./Resources/Text";

export class ControlGroupWizard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            controlgroups: [],
            loading: true,
            currentGroupIndex: 0,
            validating: false,
            validated: false,
            showValidationStatus: false,
            validationAtStep: [],
            documentCreated: false,
            nextDisabled: false,
            showTemplateInputs: true,
            errormessage: "",
            spinnerText: GetSpinnerText().DefaultSpinnerText,
        };
        this.currentGroupSize = 1;
        this._isMounted = false;
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.controlgroups !== this.state.controlgroups && this._isMounted) {
            this.inputValid();
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.populatLetterData()
        this._isMounted && this.inputValid()
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    nextGroup = async () => {
        this.validateGroups();
    };

    previousGroup = () => {
        if (this.state.currentGroupIndex === 0) {
            //Clear selection and go back to list
            this.props.onClearSelection();
        } else {
            //Remove validation step
            this.state.validationAtStep = this.state.validationAtStep.filter((x) => x !== this.state.currentGroupIndex);

            //Resolve new current index
            var newCurrentGroupIndex = this.state.validationAtStep[this.state.validationAtStep.length - 1];

            //Remove controls above validation point
            var updatedGroups = this.state.controlgroups.slice(0, this.state.currentGroupIndex);

            this._isMounted && this.setState({
                validated: false,
                currentGroupIndex: newCurrentGroupIndex,
                controlgroups: updatedGroups,
            });
        }
    };

    validateGroups = () => {
        this._isMounted && this.setState({ validating: true, showValidationStatus: false });

        var newCurrentIndex = this.state.controlgroups.length;

        this.props.onValidateGroup(this.state.controlgroups).then((data) => {
            if (data) {
                if (data.newRequirements) {
                    //Set new validationpoint
                    this.validateAt(newCurrentIndex);

                    this._isMounted && this.setState({
                        controlgroups: data.fieldGroups,
                        validated: data.allValid,
                        currentGroupIndex: newCurrentIndex,
                        validating: false,
                        showValidationStatus: false,
                    });
                } else {
                    this._isMounted && this.setState({
                        controlgroups: data.fieldGroups,
                        validated: data.allValid,
                        validating: false,
                        showValidationStatus: !data.allValid
                    })
                }
            }
        });
    };

    //Field value cahnaged in a specific group
    valueChangedAt = (index, x) => {
        this.state.controlgroups[index].fields.find((field) => {
            return field.id === x.id;
        }).value = x.value;
        this._isMounted && this.setState({ controlgroups: this.state.controlgroups });
        this.inputValid();
    };

    inputValid = () => {
        var valid = true;

        //Select all template control groups and validate fields
        this.state.controlgroups.map((groups) =>
            groups.fields.map((field, i) => {
                var fieldValue = isEmptyOrSpaces(field.value) ? field.defaultText : field.value;

                //Check if the field is mandatory
                if (field.isMandatory && (fieldValue === null || fieldValue.length === 0)) {
                    valid = false;
                }

                //Validate RegEx expression if given and if the field have value
                if (field.validationExpression && fieldValue) {
                    let regex = new RegExp(field.validationExpression);

                    if (!regex.test(fieldValue)) {
                        valid = false;
                    }
                }
                return valid;
            })
        );

        this.setState({ nextDisabled: !valid });
    };

    populatLetterData = () => {
        var data = this.props.controlgroups;
        this.validateAt(0);
        var currentGroupIndex = 0;

        if (data == null) {
            this._isMounted && this.setState({
                errormessage: "No data to fetch - validate source",
            });
        } else {
            this._isMounted && this.setState({
                controlgroups: data,
                loading: false,
                currentGroupIndex: currentGroupIndex,
            });
        }
        this.inputValid()
    };

    //Set a serverside validation point
    validateAt = (index) => {
        var validationAt = this.state.validationAtStep;
        if (!validationAt.includes(index)) {
            validationAt.push(index);
            this._isMounted && this.setState({ validationAtStep: validationAt });
        }
    };

    clearAllValues = (GroupIndex) => {
        this.state.controlgroups[GroupIndex].fields.map((x) => {
            x.value = "";
        });
        this.inputValid();
    };
    errorMessage = (text) => (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={false} onDismiss={true} dismissButtonAriaLabel="Close">
            {text} <Link size="sm" color="link" onClick={() => window.location.reload()}> Reload </Link>
        </MessageBar>
    );
    render() {
        return (
            <div>
                {this.state.errormessage.length > 0 ? this.errorMessage(this.state.errormessage) : null}
                {this.state.showValidationStatus ? <div> Values are valid? : {this.state.validated + ""} </div> : null}

                {this.state.loading || this.state.validating ? (
                    <Spinner label={this.state.spinnerText} size={SpinnerSize.large} SpinnerSize={SpinnerSize.large} ariaLive="assertive" labelPosition="right" />
                ) : (
                        <div>
                            <Row>
                                {
                                    //Show all groups on same step
                                    this.state.controlgroups.slice(this.state.currentGroupIndex).map((data, key) => {
                                        var groupIndex = key + this.state.currentGroupIndex;

                                        return (
                                            <Col className="pb-5" xs="6" sm="3" lg="2" key={key}>
                                                <ControlGroup
                                                    onClearAllValues={() => this.clearAllValues(groupIndex)}
                                                    groupIndex={groupIndex}
                                                    valueChanged={(x) => this.valueChangedAt(groupIndex, x)}
                                                    control={data}
                                                    onAllValid={this.inputValid} />
                                            </Col>
                                        );
                                    })
                                }
                            </Row>
                            <div className="controlGroupsNavigation">
                                <hr />

                                <div className="mr-5">
                                    <span>
                                        <DefaultButton onClick={this.previousGroup}>{GetControlGroupWizardText().ButtonBack} </DefaultButton>
                                        {
                                            //Show only next button if more steps
                                            this.state.currentGroupIndex < this.state.controlgroups.length - 1 || this.state.validated === false ? (
                                                <span>
                                                    {" "}
                                                    <PrimaryButton disabled={this.state.nextDisabled} onClick={this.nextGroup}>
                                                        {GetControlGroupWizardText().ButtonNext}
                                                    </PrimaryButton>
                                                </span>
                                            ) : null
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        );
    }
}
function isEmptyOrSpaces(str) {
    return str === null || str.match(/^ *$/) !== null;
}
export default ControlGroupWizard;